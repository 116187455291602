import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
import Select from "react-select";
import { CustomToast } from '../../../components/Toast/CustomToast';
import { formatCurrency } from '../../../utils/Utils'
import useDebounce from '../../../hooks/useDebounce';
import ConfirmationDialog from '../../../components/Confirmation/ConfirmationDialog';
import { ExportEntryToPdf } from './ExportEntryToPdf';
import { axiosInstance, Vehicule, VehiculeEntreeService, Groupe } from '../../../services/api';
import { AuthContext } from '../../../contexts/AuthContext';

const ListEntrees = () => {
    const { authData } = useContext(AuthContext);

    const [entrees, setEntrees] = useState([]);
    const [vehicules, setVehicules] = useState([]);
    const [groupes, setGroupes] = useState([]);
    const [filtredEntrees, setFiltredEntrees] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [idEntreeServiceDelete, setIdEntreeServiceDelete] = useState('');

    const [confirmDialogOpenDelete, setConfirmDialogOpenDelete] = useState(false);

    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState('');

    const [filters, setFilters] = useState({
        idVehicule: '',
        vehicule: '',
        typeEntreeService: '',
        idGroupe: '',
        dateDebutService: moment("00", "hh").add(-4, 'days').format('YYYY-MM-DD'),
        dateFinService: moment("00", "hh").add("", 'days').format('YYYY-MM-DD')
    });

    //const [search, setSearch] = useState("");

    const [enteredVehicleText, setEnteredVehicleText] = useState('');
    const debouncedText = useDebounce(enteredVehicleText, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredEntrees].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredEntrees(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idVehicule) => {
        setOpenAboutList(openAboutList === idVehicule ? null : idVehicule);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const handleVehicleChange = (selectedOption) => {
        setSelectedVehicle(selectedOption);
        setSelectedVehicleId(selectedOption ? selectedOption.idVehicule : '');
        filters.idVehicule = selectedVehicleId;
        filters.vehicule = selectedOption;

        const updatedFilters = { ...filters, idVehicule: selectedOption ? selectedOption.idVehicule : '' };
        setFilters(updatedFilters);
    };

    //Supprimer entree

    const handleConfirmDelete = (idEntreeService) => {
        // Show the modal when the confirm delete button is clicked
        setIdEntreeServiceDelete(idEntreeService);
        setConfirmDialogOpenDelete(true);
    };

    const handleDelete = async () => {
        try {
            const request = {
                idEntreeService: idEntreeServiceDelete,
                utilisateurAction: authData.nomPrenom,
                idUtilisateurAction: authData.idUser
            }
            await axiosInstance.delete(`${VehiculeEntreeService}`, { data: request });
            setIdEntreeServiceDelete('');
            setConfirmDialogOpenDelete(false);
            CustomToast("L'entrée de service a été supprimée avec succès", 'success');
            getEntrees(pageNumber, pageSize);
        }
        catch (error) {
            // Handle error
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpenDelete(false);
    };

    //Load data

    const getEntrees = async (page, newPerPage) => {
        try {
            setLoading(true);

            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                setLoading(false);
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                setLoading(false);
                return
            }

            if (filters.idVehicule !== '') {
                queryParams.append("idVehicule", filters.idVehicule);
            }

            if (filters.typeEntreeService !== '') {
                queryParams.append("typeEntreeService", filters.typeEntreeService);
            }

            if (filters.idGroupe !== '') {
                queryParams.append("idGroupe", filters.idGroupe);
            }

            if (filters.dateDebutService !== '') {
                queryParams.append("dateDebutService", filters.dateDebutService);
            }

            if (filters.dateFinService !== '') {
                queryParams.append("dateFinService", filters.dateFinService);
            }

            const response = await axiosInstance.get(`${VehiculeEntreeService}?${queryParams}`);
            const resp = await response.data;
            setEntrees(resp.data);
            setFiltredEntrees(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
            console.log(err);
        }
    }

    const getVehicules = async (nomVehicule) => {
        try {
            //console.log(nomVehicule);
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (nomVehicule !== '') {
                queryParams.append("nom", nomVehicule);
            }
            const response = await axiosInstance.get(`${Vehicule}/GetVehiculesAutocomplete?${queryParams}`);
            const resp = response.data;
            //console.log(resp.data);
            setVehicules(resp.data);
            //console.log(vehicules);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getGroupes = async () => {
        try {

            const response = await axiosInstance.get(`${Groupe}`);
            const resp = await response.data;
            setGroupes(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    //Imprimer entrée de service
    const pintEntry = async (idEntreeService) => {
        try {

            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 1
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            queryParams.append("idEntreeService", idEntreeService);

            const response = await axiosInstance.get(`${VehiculeEntreeService}?${queryParams}`);
            const resp = await response.data;

            ExportEntryToPdf(resp.data[0], idEntreeService, authData?.unite_distance, authData?.unite_moneitaire);
        }
        catch (error) {
            console.log(error);
        }
    }

    //Search
    const handleSearch = (e) => {
        e.preventDefault();
        setPageNumber(1); // Reset page number when new search is triggered
        getEntrees(1, pageSize); // Fetch inspections with updated filters
    };

    // useEffect(() => {
    //     // Load Data when enteredVehicleText changes
    //     getVehicules(enteredVehicleText);
    // 	return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    // }, [authData,enteredVehicleText]);

    useEffect(() => {
        //if (debouncedText) {
        getVehicules(debouncedText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedText]);

    //   useEffect(() => {
    //     getVehicules(enteredVehicleText);
    //     	return () => {
    //         // Perform any necessary cleanup (e.g., cancel pending requests)
    //     };
    //   }, [authData]);

    useEffect(() => {
        //Load Data
        getGroupes();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, []);

    useEffect(() => {
        //Load Data
        getEntrees(pageNumber, pageSize);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize]);

    return (
        <section className="service-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className="entreeservice-sec wrap-all">
                    <Link to="/ajouterentree" className="entree-service-add color-primary-2 decoration-none">
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter une entrée service
                        </div>
                    </Link>

                    <form onSubmit={handleSearch}>
                        <div className="filter-wrap">

                            <div className="date-container">
                                <input
                                    type="date"
                                    value={filters.dateDebutService}
                                    onChange={(e) => setFilters({ ...filters, dateDebutService: e.target.value })}
                                    className='date-filter'
                                />
                            </div>

                            <div className="date-container">
                                <input
                                    type="date"
                                    value={filters.dateFinService}
                                    onChange={(e) => setFilters({ ...filters, dateFinService: e.target.value })}
                                    className='date-filter'
                                />
                            </div>

                            <Select
                                id="vehicule"
                                name="vehicule"
                                value={filters.vehicule}
                                onChange={(selectedOption) => {
                                    handleVehicleChange(selectedOption);
                                }}
                                onInputChange={(inputValue) => {
                                    // Update the entered text
                                    setEnteredVehicleText(inputValue);
                                }}
                                options={vehicules}
                                getOptionLabel={(option) => option.marque + '[' + option.modele + '] ' + option.immatriculation}
                                getOptionValue={(option) => option.idVehicule}
                                isClearable
                                placeholder="Sélectionnez véhicule"
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: '1rem',
                                        borderWidth: '1px',
                                        height: '3rem',
                                        borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                        backgroundColor: '#65458d',
                                        cursor: 'pointer',
                                        color: '#fff',
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#2581DC' : '#65458d',
                                        color: '#fff',
                                        cursor: 'pointer',
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                    placeholder: (provided, state) => ({
                                        ...provided,
                                        color: '#fff'
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                }}
                            />
                            <select
                                id="idGroupe"
                                name="idGroupe"
                                className="select-filter"
                                value={filters.idGroupe || ''}
                                onChange={(e) => setFilters({ ...filters, idGroupe: e.target.value || '' })}
                            >
                                <option value="">Sélectionnez groupe</option>
                                {groupes.map((groupe) => (
                                    <option key={groupe.idGroupe} value={groupe.idGroupe}>{groupe.libelleGroupe}</option>
                                ))}
                            </select>

                            <div className="filter">
                                {/* <a href="#"><span className="material-symbols-outlined">filter_alt</span></a> */}
                                <button type="submit" className="linkButton">
                                    <span className="material-symbols-outlined">filter_alt</span>
                                </button>
                            </div>
                        </div>
                    </form>

                    {filtredEntrees?.length > 0 && <><div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        {/* <div className="search-element">
                            <p>Rechercher</p>
                            <input type="text" />
                        </div> */}
                    </div>
                        <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('nomVehicule')}>Véhicule</th>
                                        <th>Date de début de service</th>
                                        <th>Date de fin de service</th>
                                        <th>Réparation</th>
                                        <th>Fournisseur</th>
                                        <th>Compteur</th>
                                        <th>Côut</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredEntrees?.map((vehicule) => (
                                        <tr key={vehicule.idEntreeService}>
                                            <td>{vehicule.nomVehicule + ' ' + vehicule.immatriculationVehicule}</td>
                                            <td>{vehicule.dateDebutReelTravail ? (moment(vehicule.dateDebutReelTravail).format('DD-MM-YYYY')) : ''}</td>
                                            <td>{vehicule.dateFinReelTravail ? (moment(vehicule.dateFinReelTravail).format('DD-MM-YYYY')) : ''}</td>
                                            <td>{
                                                (vehicule.typeEntreeService === "interne") ? "Interne" : "Externe"
                                            }</td>
                                            <td>{vehicule.fournisseur}</td>
                                            <td>{vehicule.valeuCompteur}</td>
                                            <td>{formatCurrency(vehicule.coutTotal)}</td>
                                            <td className="txt-center relative">
                                                <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(vehicule.idEntreeService)}>
                                                    more_horiz
                                                </span>
                                                {openAboutList === vehicule.idEntreeService && (
                                                    <div className="about-list">
                                                        <ul>
                                                            {(vehicule.sourceEntree !== "ordre travail") && <li>
                                                                <Link className='row row-center' to={`/modifierentree/${vehicule.idEntreeService}`} style={{ textDecoration: "none" }}>
                                                                    <span className="material-symbols-outlined">edit</span>Modifier
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.sourceEntree === "ordre travail") && <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()} title="Les entrées de service générées par les ordres de travail ne peuvent pas être modifiées">
                                                                    <span className="material-symbols-outlined">edit</span>Modifier
                                                                </Link>
                                                            </li>}

                                                            <li>
                                                                <Link className='row row-center' to={`/apercuservice/${vehicule.idEntreeService}`} style={{ textDecoration: "none" }}>
                                                                    <span className="material-symbols-outlined">visibility</span>Aperçu
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none" }} onClick={() => pintEntry(vehicule.idEntreeService)}>
                                                                    <span className="material-symbols-outlined">print</span>Imprimer
                                                                </Link>
                                                            </li>

                                                            {(vehicule.sourceEntree !== "ordre travail") && <li>
                                                                <Link className='row row-center' to="/listentrees" style={{ textDecoration: "none" }} onClick={() => handleConfirmDelete(vehicule.idEntreeService)}>
                                                                    <span className="material-symbols-outlined">delete</span>Supprimer
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.sourceEntree === "ordre travail") && <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()} title="Les entrées de service générées par les ordres de travail ne peuvent pas être supprimées">
                                                                    <span className="material-symbols-outlined">delete</span>Supprimer
                                                                </Link>
                                                            </li>}

                                                        </ul>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div> */}
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                const page = pageNumber - 2 + i;
                                return page <= totalPages && page > 0 && (
                                    <Link
                                        key={page}
                                        to="#"
                                        className={pageNumber === page ? 'pgn active' : 'pgn'}
                                        onClick={() => handlePageChange(page)}
                                    >
                                        {page}
                                    </Link>
                                );
                            })}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={(pageNumber === totalPages || totalPages === 0) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === totalPages || totalPages === 0) ? true : false}
                            >&raquo;</Link>
                            <span> Affichage de {(pageNumber === 1)?1:((pageNumber - 1) * pageSize+1)} à {pageNumber * pageSize} sur {totalItems} entrées</span>
                        </div>
                    </>}

                    <ConfirmationDialog
                        open={confirmDialogOpenDelete}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression de l'entrée de service"
                        bodyMessage={"Voulez-vous vraiment supprimer cette entrée de service ? Toutes les données seront définitivement perdues."}
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer l'entrée de service"
                    />
                </div>
            </div>
        </section>
    );
}

export default ListEntrees;
