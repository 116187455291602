import React, { useEffect, useState, useRef, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import Select from "react-select";
import useDebounce from '../../../hooks/useDebounce';
import { formatCurrency } from '../../../utils/Utils'
import { CustomToast } from '../../../components/Toast/CustomToast';
import { axiosInstance, Vehicule, Fournisseur, Employee, TacheService, VehiculeServiceRappelle, VehiculeEntreeService, VehiculeProbleme } from '../../../services/api';
import { AuthContext } from '../../../contexts/AuthContext';
const ModifierEntree = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();
    const { id } = useParams();//idEntreeService

    const [details, setDetails] = useState(true);
    const [problemes, setProblemes] = useState(false);
    const [taches, setTaches] = useState(false);
    const [documents, setDocuments] = useState(false);

    const [detailsClass, setDetailsClass] = useState("line act");
    const [problemesClass, setProblemesClass] = useState("line");
    const [tachesClass, setTachesClass] = useState("line");
    const [documentsClass, setDocumentsClass] = useState("line");

    const [entryLoaded, setEntryLoaded] = useState();
    const [vehicules, setVehicules] = useState([]);
    const [fournisseurs, setFournisseurs] = useState([]);
    const [utilisateurs, setUtilisateurs] = useState([]);
    const [tachesService, setTachesService] = useState([]);
    const [rappels, setRappels] = useState([]);
    const [problemesVehicule, setProblemesVehicule] = useState([]);

    const [selectedVehicleId, setSelectedVehicleId] = useState('');

    const [kiloDebLess, setKiloDebLess] = useState({ display: false, msg: "" });
    const [kiloDebGreater, setKiloDebGreater] = useState({ display: false, msg: "" });
    const [kiloDebBetween, setKiloDebBetween] = useState({ display: false, msg: "" });

    const [listPathDocumentsJustificatifs, setListPathDocumentsJustificatifs] = useState([]);
    const [listPathDocumentsJustificatifsName, setListPathDocumentsJustificatifsName] = useState([]);
    const listPathDocumentsJustificatifsRef = useRef(null);

    const [selectedTache, setSelectedTache] = useState('');
    const [selectedTacheId, setSelectedTacheId] = useState('');

    const [enteredTaskText, setEnteredTaskText] = useState('');
    const debouncedTaskText = useDebounce(enteredTaskText, 300);

    const showDetails = () => {
        setDetails(true);
        setProblemes(false);
        setTaches(false);
        setDocuments(false);

        setDetailsClass("line act");
        setProblemesClass("line");
        setTachesClass("line");
        setDocumentsClass("line");
    };

    const showProblemes = () => {
        setDetails(false);
        setProblemes(true);
        setTaches(false);
        setDocuments(false);

        setDetailsClass("line");
        setProblemesClass("line act");
        setTachesClass("line");
        setDocumentsClass("line");
    };

    const showTaches = () => {
        setDetails(false);
        setProblemes(false);
        setTaches(true);
        setDocuments(false);

        setDetailsClass("line");
        setProblemesClass("line");
        setTachesClass("line act");
        setDocumentsClass("line");
    };

    const showDocuments = () => {
        setDetails(false);
        setProblemes(false);
        setTaches(false);
        setDocuments(true);

        setDetailsClass("line");
        setProblemesClass("line");
        setTachesClass("line");
        setDocumentsClass("line act");
    };

    const onCheck = (id) => {
        //console.log(id);
        setProblemesVehicule((prevProblemes) => {
            return prevProblemes.map((probleme) => {
                if (probleme.idProbleme === id) {
                    return {
                        ...probleme,
                        acces: !probleme.acces, // Toggle the value of the access field
                    };
                }
                return probleme;
            });
        });
    };

    //Upload documents
    const handlePathImageChange = (e) => {
        const files = Array.from(e.target.files); // Convert the FileList to an array
        setListPathDocumentsJustificatifs(files);
    };

    const handleDeleteFile = (indexToDelete) => {
        setListPathDocumentsJustificatifs((prevFiles) =>
            prevFiles.filter((file, index) => index !== indexToDelete)
        );
    };

    const handleDeleteFileName = (indexToDelete) => {
        setListPathDocumentsJustificatifsName((prevFiles) =>
            prevFiles.filter((file, index) => index !== indexToDelete)
        );
    };

    //Load data

    const getEntry = async (idEntreeService) => {
        try {
            const response = await axiosInstance.get(`${VehiculeEntreeService}/${idEntreeService}`);
            const resp = await response.data;
            setEntryLoaded(resp);

            setValues(prevValues => ({
                ...prevValues,
                vehicule: '',
                numeroEntreeService: resp.numeroEntreeService,
                idVehicule: resp.idVehicule,
                typeEntreeService: resp.typeEntreeService || 'interne',
                delivrerPar: resp.delivrerPar || '',
                delivrerParId: resp.delivrerParId || '',
                dateDebutReelTravail: moment(resp.dateDebutReelTravail).isValid() ? moment(resp.dateDebutReelTravail).format('YYYY-MM-DD') : '' || '',
                valeuCompteur: resp.valeuCompteur || '',
                dateFinReelTravail: moment(resp.dateFinReelTravail).isValid() ? moment(resp.dateFinReelTravail).format('YYYY-MM-DD') : '' || '',
                idFournisseur: resp.idFournisseur || '',
                numeroFacture: resp.numeroFacture || '',
                numeroBonCommande: resp.numeroBonCommande || '',
                listeProblemes: [],
                listeItems: resp.listeItems || [],
                remarqesGenerale: resp.remarqesGenerale || '',
                coutMainOeuvre: resp.coutMainOeuvre || 0,
                coutPiecesRechange: resp.coutPiecesRechange || 0,
                coutTotal: resp.coutTotal || 0,
                commentaire: resp.commentaire || '',
                listPathDocumentsJustificatifs: resp.listPathDocumentsJustificatifs || [],
                defenirDateDeb: resp.dateDebutReelTravail ? true : false,
            }));

            if (resp.listeItems) {
                const listeItems = resp.listeItems
                    .map((item) => ({
                        typeItem: item.typeItem,
                        idServiceRappelle: item.idServiceRappelle,
                        nouveauRappele: 'old',
                        idTacheService: item.idTacheService,
                        libelleTacheService: item.libelleTacheService,
                        descriptionTacheService: item.descriptionTacheService,
                        remarque: item.remarque,
                        coutMainOeuvre: item.coutMainOeuvre,
                        coutPiecesRechange: item.coutPiecesRechange,
                        coutTotal: item.coutTotal,
                        isCoutMainOeuvreEnabled: (item.coutMainOeuvre == 0 && item.coutPiecesRechange == 0 && item.coutTotal > 0) ? false : true,
                        isCoutPiecesRechangeEnabled: (item.coutMainOeuvre == 0 && item.coutPiecesRechange == 0 && item.coutTotal > 0) ? false : true,
                        isCoutTotalEnabled: (item.coutMainOeuvre > 0 || item.coutPiecesRechange > 0) ? false : true,
                        showRemarque: true
                    }));
                setItems(listeItems)
            }

            setListPathDocumentsJustificatifsName(resp.listPathDocumentsJustificatifs || '')

        }
        catch (err) {
            console.log(err);
        }
    }

    const getVehicules = async (idVehicule) => {
        try {
            const response = await axiosInstance.get(`${Vehicule}/GetById/${idVehicule}`);
            const resp = await response.data;

            setFieldValue('vehicule', resp.nom + ' ' + resp.immatriculation || '');
        }
        catch (err) {
            console.log(err);
        }
    }

    const getFournisseurs = async () => {
        try {
            const queryParams = new URLSearchParams({
                service: true
            });

            const response = await axiosInstance.get(`${Fournisseur}/GetFournisseurType?${queryParams}`);
            const resp = await response.data;
            setFournisseurs(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getProblemes = async (idVehicule) => {
        try {

            const queryParams = new URLSearchParams({
                idVehicule: idVehicule,
                status: "ouvert,en retard"
            });

            const response = await axiosInstance.get(`${VehiculeProbleme}/GetProblemesVehicule?${queryParams}`);
            const resp = await response.data;
            setProblemesVehicule(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getTaches = async (nomTache) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (nomTache !== '') {
                queryParams.append("nom", nomTache);
            }

            const response = await axiosInstance.get(`${TacheService}/GetTachesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setTachesService(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const { handleChange, values, handleSubmit, touched, errors, setFieldValue, setValues } = useFormik({
        initialValues: {
            vehicule: '',
            idVehicule: '',
            typeEntreeService: 'interne',
            delivrerPar: '',
            delivrerParId: '',
            dateDebutReelTravail: '',
            valeuCompteur: '',
            dateFinReelTravail: '',
            idFournisseur: '',
            numeroFacture: '',
            numeroBonCommande: '',
            listeProblemes: [],
            listeItems: [],
            coutMainOeuvre: 0,
            coutPiecesRechange: 0,
            coutTotal: 0,
            remarqesGenerale: '',
            commentaire: '',
            defenirDateDeb: false,
        },
        validationSchema: Yup.object({
            defenirDateDeb: Yup.bool(),
            valeuCompteur: Yup.number()
                .required('Champ obligatoire')
                .min(0, "Valeur de compteur ne peut pas être négative"),
            dateFinReelTravail: Yup.string()
                .required('Champ obligatoire'),
            dateDebutReelTravail: Yup.string().test('dateDeb-validation', 'Invalid dateDeb', function (value) {
                const defenirDateDeb = this.resolve(Yup.ref('defenirDateDeb'));

                if (defenirDateDeb) {
                    if (!value) {
                        return this.createError({ message: 'Champ obligatoire' });
                    }
                }

                return true;
            }),
        }),
        onSubmit: async (values) => {
            try {

                const listProblemesResolus = problemesVehicule
                    .filter(item => item.acces === true)
                    .map((item) => ({
                        idProbleme: item.idProbleme,
                        titre: item.titre,
                        description: item.description,
                        affecterA: item.affecterA,
                        dateLimit: moment(item.dateLimit).isValid() ? item.dateLimit : '' || ''
                    }));

                const listeItems = items
                    .map((item) => ({
                        typeItem: item.typeItem,
                        idServiceRappelle: item.idServiceRappelle,
                        idTacheService: item.idTacheService,
                        libelleTacheService: item.libelleTacheService,
                        descriptionTacheService: item.descriptionTacheService,
                        remarque: item.remarque,
                        coutMainOeuvre: item.coutMainOeuvre.toString().replace('.', ','),
                        coutPiecesRechange: item.coutPiecesRechange.toString().replace('.', ','),
                        coutTotal: item.coutTotal.toString().replace('.', ','),
                    }));

                //console.log(listeItems);

                const formData = new FormData();
                formData.append('idEntreeService', id);
                formData.append('idVehicule', values.idVehicule);
                formData.append('typeEntreeService', values.typeEntreeService);
                formData.append('delivrerPar', authData.nomPrenom);
                formData.append('delivrerParId', authData.idUser);

                if (values.defenirDateDeb) {
                    formData.append('dateDebutReelTravail', values.dateDebutReelTravail);
                }

                formData.append('valeuCompteur', values.valeuCompteur.toString().replace('.', ','));
                formData.append('dateFinReelTravail', values.dateFinReelTravail);
                formData.append('idFournisseur', values.idFournisseur);
                formData.append('numeroFacture', values.numeroFacture);
                formData.append('numeroBonCommande', values.numeroBonCommande);

                for (let i = 0; i < listProblemesResolus.length; i++) {
                    for (let key of Object.keys(listProblemesResolus[i])) {
                        formData.append(`listeProblemes[${i}][${key}]`, listProblemesResolus[i][key]);
                    }
                }

                for (let i = 0; i < listeItems.length; i++) {
                    for (let key of Object.keys(listeItems[i])) {
                        formData.append(`listeItems[${i}][${key}]`, listeItems[i][key]);
                    }
                }

                formData.append('remarqesGenerale', values.remarqesGenerale);
                formData.append('coutMainOeuvre', values.coutMainOeuvre.toString().replace('.', ','));
                formData.append('coutPiecesRechange', values.coutPiecesRechange.toString().replace('.', ','));
                formData.append('coutTotal', values.coutTotal.toString().replace('.', ','));
                formData.append('commentaire', values.commentaire);

                listPathDocumentsJustificatifs.forEach((file, index) => {
                    formData.append('listPathDocumentsJustificatifs', file);
                });

                listPathDocumentsJustificatifsName.forEach((file, index) => {
                    formData.append(`listPathDocumentsJustificatifsName[${index}]`, file);
                });


                formData.append('idUtilisateurAction', authData.idUser);

                // formData.forEach((value, key) => {
                //     console.log(`${key}: ${value}`);
                // });

                await axiosInstance.put(VehiculeEntreeService, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                CustomToast("L'entrée de service a été modifiée avec succès", 'success');
                navigate('/listentrees', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data === "Vehicle not found") {
                    CustomToast("Véhicule introuvable", 'error');
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                } else if (error.response?.status === 400 && error.response?.data === "Service entry not found") {
                    CustomToast("Entrée de service introuvable", 'error');
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });

                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect value, must be greater") {
                    CustomToast("L'entrée de compteur de véhicule n'a pas pu être créée", 'error');
                    setKiloDebGreater({ display: true, msg: `Doit être supérieur à ${error.response?.data?.val1}` });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect value, must be less") {
                    CustomToast("L'entrée de compteur de véhicule n'a pas pu être créée", 'error');
                    setKiloDebLess({ display: true, msg: `Doit être inférieur à ${error.response?.data?.val1}` });
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else if (error.response?.status === 400 && error.response?.data?.msg === "Incorrect value, must be between") {
                    CustomToast("L'entrée de compteur de véhicule n'a pas pu être créée", 'error');
                    setKiloDebBetween({ display: true, msg: `Doit être entre ${error.response?.data?.val1} et ${error.response?.data?.val2}` });
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                } else {
                    CustomToast("Demande échoué", 'error');
                    setKiloDebGreater({ display: false, msg: "" });
                    setKiloDebLess({ display: false, msg: "" });
                    setKiloDebBetween({ display: false, msg: "" });
                }
                console.log(error);
            }
        }
    });

    //Ajouter items
    const handleTacheChange = (selectedOption) => {
        setSelectedTache(selectedOption);
        setSelectedTacheId(selectedOption ? selectedOption.idTacheService : '');

        if (!selectedOption) {
            return;
        }

        const newItem = {
            typeItem: 'tache de service',
            idServiceRappelle: '',
            //nouveauRappele: this.typeItem === 'tache de service' ? '' : 'new',
            nouveauRappele: '',
            idTacheService: selectedOption?.idTacheService || '',
            libelleTacheService: selectedOption?.libelle || '',
            descriptionTacheService: selectedOption?.description || '',
            remarque: '',
            coutMainOeuvre: 0,
            coutPiecesRechange: 0,
            coutTotal: 0,
            isCoutMainOeuvreEnabled: true,
            isCoutPiecesRechangeEnabled: true,
            isCoutTotalEnabled: true,
            showRemarque: true
        };

        addItem(newItem);
        setSelectedTache(null);
    };

    const [items, setItems] = useState([]);

    const addItem = (newItem) => {
        setItems([...items, newItem]);
    };

    const deleteItem = (itemIndex) => {
        const updatedItems = items.filter((item, index) => index !== itemIndex);
        setItems(updatedItems);
    };

    // Function to handle changes in "coutMainOeuvre" input
    const handleCoutMainOeuvreChange = (index, newValue) => {
        const updatedItems = [...items];
        if (!newValue) {
            newValue = 0;
        }
        updatedItems[index].coutMainOeuvre = newValue;
        const coutPiecesRechange = parseFloat(updatedItems[index].coutPiecesRechange.toString().replace(/,/, '.'));
        const coutMainOeuvre = parseFloat(newValue.toString().replace(/,/, '.'));

        // Calculate "coutTotal" based on the updated values
        updatedItems[index].coutTotal = parseFloat(updatedItems[index].coutPiecesRechange.toString().replace(/,/, '.'), 2) + parseFloat(newValue.toString().replace(/,/, '.'), 2);
        // Disable coutTotal if either coutMainOeuvre or coutPiecesRechange is greater than 0
        updatedItems[index].isCoutTotalEnabled = !(coutMainOeuvre > 0 || coutPiecesRechange > 0);

        setItems(updatedItems);
    };

    // Function to handle changes in "coutPiecesRechange" input
    const handleCoutPiecesRechangeChange = (index, newValue) => {
        const updatedItems = [...items];
        if (!newValue) {
            newValue = 0;
        }
        updatedItems[index].coutPiecesRechange = newValue;
        // Calculate "coutTotal" based on the updated values
        const coutPiecesRechange = parseFloat(newValue.toString().replace(/,/, '.'));
        const coutMainOeuvre = parseFloat(updatedItems[index].coutMainOeuvre.toString().replace(/,/, '.'));

        updatedItems[index].coutTotal = parseFloat(newValue.toString().replace(/,/, '.'), 2) + parseFloat(updatedItems[index].coutMainOeuvre.toString().replace(/,/, '.'), 2);
        // Disable coutTotal if either coutMainOeuvre or coutPiecesRechange is greater than 0
        updatedItems[index].isCoutTotalEnabled = !(coutMainOeuvre > 0 || coutPiecesRechange > 0);

        setItems(updatedItems);
    };

    // Function to handle changes in "coutTotal" input
    const handleCoutTotalChange = (index, newValue) => {
        const updatedItems = [...items];
        if (!newValue) {
            newValue = 0;
        }
        updatedItems[index].coutTotal = newValue;
        // Calculate "coutPiecesRechange" based on the updated values
        updatedItems[index].coutPiecesRechange = 0;
        // Calculate "coutMainOeuvre" based on the updated values
        updatedItems[index].coutMainOeuvre = 0;

        // Disable coutMainOeuvre and coutPiecesRechange if coutTotal is greater than 0
        updatedItems[index].isCoutMainOeuvreEnabled = !(newValue > 0);
        updatedItems[index].isCoutPiecesRechangeEnabled = !(newValue > 0);

        setItems(updatedItems);
    };

    // Function to handle changes in "remarque" textarea
    const handleRemarqueChange = (index, newValue) => {
        const updatedItems = [...items];
        updatedItems[index].remarque = newValue;
        setItems(updatedItems);
    };

    //Function to show/hide field "remarque" textarea
    const onShowRemarque = (index) => {
        //console.log(index);
        const updatedItems = [...items];
        updatedItems[index].showRemarque = !updatedItems[index].showRemarque;
        setItems(updatedItems);
    };

    const calculateTotals = () => {
        let totalCoutMainOeuvre = 0;
        let totalCoutPiecesRechange = 0;
        let totalCoutTotal = 0;

        // Iterate through the items to calculate totals
        items.forEach((item) => {
            totalCoutMainOeuvre += parseFloat(item.coutMainOeuvre.toString().replace(/,/, '.'));
            totalCoutPiecesRechange += parseFloat(item.coutPiecesRechange.toString().replace(/,/, '.'));
            totalCoutTotal += parseFloat(item.coutTotal.toString().replace(/,/, '.'));
        });

        return {
            totalCoutMainOeuvre,
            totalCoutPiecesRechange,
            totalCoutTotal,
        };
    };

    const updateTotals = () => {
        const { totalCoutMainOeuvre, totalCoutPiecesRechange, totalCoutTotal } = calculateTotals();

        setFieldValue('coutMainOeuvre', totalCoutMainOeuvre.toFixed(2));
        setFieldValue('coutPiecesRechange', totalCoutPiecesRechange.toFixed(2));
        setFieldValue('coutTotal', totalCoutTotal.toFixed(2));
    };

    //Load data

    useEffect(() => {
        getEntry(id);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [id])

    useEffect(() => {
        //if (debouncedTaskText) {
        getTaches(debouncedTaskText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [debouncedTaskText]);

    useEffect(() => {
        if (values.idVehicule !== '' && values.vehicule === '') {
            getVehicules(values.idVehicule);
        }
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [entryLoaded])

    useEffect(() => {
        getFournisseurs();
        //getTaches();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [entryLoaded])

    useEffect(() => {
        if (values.idVehicule !== '' && values.vehicule === '') {
            getProblemes(values.idVehicule);
            //console.log("getProblemes(values.idVehicule);")
        }
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [entryLoaded])

    useEffect(() => {
        updateTotals();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [items])

    return (
        <section className="service-section">
            <div className="container">
                <form onSubmit={handleSubmit} className="entreeservice-form">
                    <div className="">
                        <div className="grid grid--form-cols">
                            <div className="form-sec grid--grid-area-3">
                                <div className="retour" id="retour-entreeservice">
                                    <Link to="/listentrees" style={{ textDecoration: "none" }}>
                                        <div className="arrow-back">
                                            <span className="material-symbols-outlined" id="arrow">arrow_back</span>
                                        </div>
                                    </Link>
                                </div>
                                {entryLoaded && <div className="small-menu-sec">
                                    <div id="detail-entree-service" className={detailsClass} onClick={() => showDetails()}>
                                        <span className="material-symbols-outlined">info</span>
                                        <h3 className="heading-primary">Détails</h3>
                                        {(errors.valeuCompteur || errors.dateFinReelTravail || (values.defenirDateDeb === true && errors.dateDebutReelTravail)) && <span className="material-symbols-outlined danger">error</span>}
                                    </div>
                                    <div className={problemesClass} id="problem-entree-service" onClick={() => showProblemes()}>
                                        <span className="material-symbols-outlined">report</span>
                                        <h3 className="heading-primary">Problèmes</h3>
                                    </div>
                                    <div className={tachesClass} id="tache-service-entree" onClick={() => showTaches()}>
                                        <span className="material-symbols-outlined">settings</span>
                                        <h3 className="heading-primary">Tâches de service</h3>
                                    </div>
                                    <div className={documentsClass} id="tache-service-entree" onClick={() => showDocuments()}>
                                        <span className="material-symbols-outlined">upload_file</span>
                                        <h3 className="heading-primary">Documents</h3>
                                    </div>
                                </div>}
                            </div>

                            {entryLoaded && <div className="form-sec grid--grid-area-4">
                                <div className="retour" id="retour-entree-service-btn">
                                    <div className="arrow-back">

                                    </div>
                                    <button type="submit" className="submit-btn">Enregistrer</button>
                                </div>
                                <div className="form-details">
                                    {details && <div className="detail-form" id="detail-entree-service-form">
                                        <h3 className="heading-primary">Détails</h3>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Véhicule<span className='requiredField'>*</span></label>
                                                    {/* <label >{values.vehicule}</label> */}
                                                    <input
                                                        type="text"
                                                        name="vehicule"
                                                        id="vehicule"
                                                        value={values.vehicule}
                                                        disabled={true} />
                                                </div>
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label>Compteur<span className='requiredField'>*</span></label>
                                                    <input
                                                        type="number"
                                                        id="valeuCompteur"
                                                        name="valeuCompteur"
                                                        placeholder=""
                                                        defaultValue={values.valeuCompteur}
                                                        onChange={handleChange}
                                                        step="any"
                                                    />
                                                    {errors.valeuCompteur && touched.valeuCompteur && (
                                                        <div className="invalid-feedback">{errors.valeuCompteur}</div>
                                                    )}
                                                    {kiloDebLess.display && (
                                                        <div className="invalid-feedback">{kiloDebLess.msg}</div>
                                                    )}
                                                    {kiloDebGreater.display && (
                                                        <div className="invalid-feedback">{kiloDebGreater.msg}</div>
                                                    )}
                                                    {kiloDebBetween.display && (
                                                        <div className="invalid-feedback">{kiloDebBetween.msg}</div>
                                                    )}
                                                </div>
                                                <div className="gp">
                                                    <label>Date d'achèvement<span className='requiredField'>*</span></label>
                                                    <input type="date"
                                                        id="dateFinReelTravail"
                                                        name="dateFinReelTravail"
                                                        style={(touched.dateFinReelTravail && errors.dateFinReelTravail) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.dateFinReelTravail}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.dateFinReelTravail && errors.dateFinReelTravail ? (
                                                        <div className="invalid-feedback">{errors.dateFinReelTravail}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="grp-check">
                                                <input
                                                    type="checkbox"
                                                    className="box"
                                                    id="defenirDateDeb"
                                                    name="defenirDateDeb"
                                                    checked={values.defenirDateDeb}
                                                    onChange={handleChange}
                                                />
                                                <label> Définir la date de début</label>
                                            </div>
                                            <div className="grp">
                                                {values.defenirDateDeb && <div className="gp">
                                                    <label>Date de début<span className='requiredField'>*</span></label>
                                                    <input type="date"
                                                        id="dateDebutReelTravail"
                                                        name="dateDebutReelTravail"
                                                        style={(touched.dateDebutReelTravail && errors.dateDebutReelTravail) ? { borderColor: "red" } : null}
                                                        placeholder=""
                                                        defaultValue={values.dateDebutReelTravail}
                                                        onChange={handleChange}
                                                    />
                                                    {touched.dateDebutReelTravail && errors.dateDebutReelTravail ? (
                                                        <div className="invalid-feedback">{errors.dateDebutReelTravail}</div>
                                                    ) : null}
                                                </div>}
                                            </div>
                                            <div className="grp">
                                                <div className="gp">
                                                    <label >Réparation</label>
                                                    <select
                                                        id="typeEntreeService"
                                                        name="typeEntreeService"
                                                        value={values.typeEntreeService}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="interne" defaultValue={true}>Interne</option>
                                                        <option value="externe">Externe</option>
                                                    </select>
                                                </div>
                                                <div className="gp">
                                                    <label >Fournisseur</label>
                                                    <select
                                                        id="idFournisseur"
                                                        name="idFournisseur"
                                                        value={values.idFournisseur}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Veuillez sélectionner</option>
                                                        {fournisseurs.map((fournisseur) => (
                                                            <option key={fournisseur.idFournisseur} value={fournisseur.idFournisseur}>{fournisseur.raisonSociale}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="gp">
                                                    <label title='ex: N° de facture'>Référence</label>
                                                    <input
                                                        id="numeroFacture"
                                                        name="numeroFacture"
                                                        type="text"
                                                        value={values.numeroFacture}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {problemes && <div className="detail-form" id="problem-entree-form">
                                        <h3 className="heading-primary">Problèmes</h3>
                                        <label style={{ fontSize: '1rem', fontStyle: 'italic' }}>Sélectionnez tous les problèmes résolus dans le cadre de ce service</label>
                                        <div className="col">
                                            <div className="grp">
                                                <div className="gp">
                                                    {problemesVehicule && <table className="element-inspec-table">
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Problème</th>
                                                                <th>Résumé</th>
                                                                <th>Priorité</th>
                                                                <th>Statut</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {problemesVehicule?.map((item, index) => (
                                                                <tr key={index} style={{ fontSize: '1rem' }}>
                                                                    <td>
                                                                        <input type="checkbox" className="box" onClick={() => onCheck(item.idProbleme)} defaultChecked={item.acces ? item.acces : false} />
                                                                    </td>
                                                                    <td>
                                                                        <span>{item.titre.charAt(0).toUpperCase() + item.titre.slice(1)}</span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{item.description.charAt(0).toUpperCase() + item.description.slice(1)}</span>
                                                                    </td>
                                                                    {(item.priorite === "faible") && <td><span className="material-symbols-outlined" style={{ color: "grey", fontSize: '100%' }}>stat_1</span> Faible</td>}
                                                                    {(item.priorite === "moyen") && <td><span className="material-symbols-outlined" style={{ color: "yellow", fontSize: '100%' }}>stat_2</span> Moyen</td>}
                                                                    {(item.priorite === "élevé") && <td><span className="material-symbols-outlined" style={{ color: "orange", fontSize: '100%' }}>stat_3</span> Élevé</td>}
                                                                    {(item.priorite === "critique") && <td><span className="material-symbols-outlined" style={{ color: "red", fontSize: '100%' }}>error</span> Critique</td>}
                                                                    {(item.priorite === "" || item.priorite === "aucune") && <td><span className="material-symbols-outlined" style={{ fontSize: '100%' }}>radio_button_unchecked</span> Aucune</td>}
                                                                    {(item.statut === "ouvert") && <td><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="yellow" /></svg>Ouvert</td>}
                                                                    {(item.statut === "en retard") && <td><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>En retard</td>}
                                                                    {(item.statut === "") && <td></td>}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {taches && <div className="cycle-form" id="tache-service-form">
                                        <div className="detail-form">
                                            <h3 className="heading-primary">Tâches de service</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        {/* <label>Tâches de service</label> */}
                                                        <Select
                                                            id="tacheService"
                                                            name="tacheService"
                                                            value={selectedTache}
                                                            onChange={(option) => { handleTacheChange(option); }}
                                                            options={tachesService}
                                                            onInputChange={(inputValue) => {
                                                                // Update the entered text
                                                                setEnteredTaskText(inputValue);
                                                            }}
                                                            getOptionLabel={(option) => option.libelle}
                                                            getOptionValue={(option) => option.idTacheService}
                                                            isClearable
                                                            styles={{
                                                                control: (baseStyles, state) => ({
                                                                    ...baseStyles,
                                                                    minHeight: '19px',
                                                                    outline: '0!important',
                                                                    borderRadius: '0.5rem',
                                                                    borderWidth: '1px',
                                                                    fontSize: '1rem',
                                                                    height: '3.1rem',
                                                                }),
                                                                option: (provided, state) => ({
                                                                    ...provided,
                                                                    fontSize: '1rem'
                                                                }),
                                                                singleValue: (provided, state) => ({
                                                                    ...provided,
                                                                    fontSize: '1rem',
                                                                    margin: '0',
                                                                }),
                                                                valueContainer: (provided, state) => ({
                                                                    ...provided,
                                                                    alignItems: 'start',
                                                                    fontSize: '1rem'
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <table className="element-inspec-table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Tâches</th>
                                                                    <th>Main-d'oeuvre</th>
                                                                    <th>Pièces</th>
                                                                    <th>Total</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {items?.map((item, index) => (
                                                                    <React.Fragment key={index}>
                                                                        <tr key={index}>
                                                                            <td className="row row-center">
                                                                                <p title={items[index]?.descriptionTacheService}>{item.libelleTacheService}</p>
                                                                                <span className="material-symbols-outlined cursurer" onClick={() => onShowRemarque(index)}>
                                                                                    {items[index].showRemarque ? 'arrow_drop_down' : 'arrow_right'}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="number"
                                                                                    name="coutMainOeuvre"
                                                                                    id={`coutMainOeuvre-${index}`}
                                                                                    value={item.coutMainOeuvre}
                                                                                    step="any"
                                                                                    style={{ textAlign: 'right' }}
                                                                                    className="input-table-form"
                                                                                    onChange={(e) => handleCoutMainOeuvreChange(index, e.target.value)}
                                                                                    disabled={!item.isCoutMainOeuvreEnabled}
                                                                                    title={item.isCoutMainOeuvreEnabled ? '' : 'Cette valeur est automatiquement calculée par les autres colonnes et ne peut pas être directement modifiée.'}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="number"
                                                                                    name="coutPiecesRechange"
                                                                                    id={`coutPiecesRechange-${index}`}
                                                                                    value={item.coutPiecesRechange}
                                                                                    step="any"
                                                                                    style={{ textAlign: 'right' }}
                                                                                    className="input-table-form"
                                                                                    onChange={(e) => handleCoutPiecesRechangeChange(index, e.target.value)}
                                                                                    disabled={!item.isCoutPiecesRechangeEnabled}
                                                                                    title={item.isCoutPiecesRechangeEnabled ? '' : 'Cette valeur est automatiquement calculée par les autres colonnes et ne peut pas être directement modifiée.'}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <input
                                                                                    type="number"
                                                                                    name="coutTotal"
                                                                                    id={`coutTotal-${index}`}
                                                                                    value={item.coutTotal ? item.coutTotal : 0}
                                                                                    step="any"
                                                                                    style={{ textAlign: 'right' }}
                                                                                    className="input-table-form"
                                                                                    onChange={(e) => handleCoutTotalChange(index, e.target.value)}
                                                                                    disabled={!item.isCoutTotalEnabled}
                                                                                    title={item.isCoutTotalEnabled ? '' : 'Cette valeur est automatiquement calculée par les autres colonnes et ne peut pas être directement modifiée.'}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <span
                                                                                    className="material-symbols-outlined cursurer danger" onClick={() => deleteItem(index)}>delete</span>
                                                                            </td>
                                                                        </tr>
                                                                        {items[index].showRemarque && <tr key={`TRremarque-${index}`}>
                                                                            <td colSpan={4}>
                                                                                <textarea
                                                                                name="remarque"
                                                                                id={`remarque-${index}`}
                                                                                className="discp3"
                                                                                value={item?.remarque ? item?.remarque : ''}
                                                                                cols="100"
                                                                                rows="03"
                                                                                onChange={(e) => handleRemarqueChange(index, e.target.value)}
                                                                                placeholder='Ajouter des notes ou des détails supplémentaires'
                                                                                style={{ width: '100%', height: '20%' }}
                                                                           
                                                                           ></textarea>
                                                                            </td>
                                                                        </tr>}
                                                                    </React.Fragment>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label>Notes générales</label>
                                                        <textarea
                                                            name="remarqesGenerale"
                                                            id="remarqesGenerale"
                                                            value={values.remarqesGenerale}
                                                            rows={4}
                                                            onChange={handleChange}
                                                            placeholder='Ajouter des notes ou des détails supplémentaires'
                                                        ></textarea>
                                                    </div>
                                                    <div className="gp">
                                                        <label>Résumé des coûts</label>
                                                        <table className='cost-summary'>
                                                            <tbody>
                                                                <tr>
                                                                    <th className='td-left td-weight-normal'>Main-d'œuvre</th>
                                                                    <td className='td-right td-weight-normal'>{formatCurrency(values.coutMainOeuvre)} {authData?.unite_moneitaire}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className='td-left td-weight-normal'>Pièces</th>
                                                                    <td className='td-right td-weight-normal'>{formatCurrency(values.coutPiecesRechange)} {authData?.unite_moneitaire}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th className='td-left td-weight-bold'>Total</th>
                                                                    <td className='td-right td-weight-bold'>{formatCurrency(values.coutTotal)} {authData?.unite_moneitaire}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {documents && <div className="detail-form" id="detail-entree-service-form">
                                        <div className="detail-form">
                                            <h3 className="heading-primary">Documents</h3>
                                            <div className="col">
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label>Commentaires</label>
                                                        <textarea
                                                            id="commentaire"
                                                            name="commentaire"
                                                            type="text"
                                                            value={values.commentaire}
                                                            onChange={handleChange}
                                                            rows={4}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="grp">
                                                    <div className="gp">
                                                        <label >Documents</label>
                                                        <label className="upload-file">
                                                            upload file
                                                            <input
                                                                ref={listPathDocumentsJustificatifsRef}
                                                                id="listPathDocumentsJustificatifs"
                                                                name="listPathDocumentsJustificatifs"
                                                                type="file"
                                                                onChange={handlePathImageChange}
                                                                multiple
                                                            />
                                                        </label>
                                                        <div style={((listPathDocumentsJustificatifs || listPathDocumentsJustificatifsName) && ((listPathDocumentsJustificatifs?.length + listPathDocumentsJustificatifsName?.length)) > 3) ? { fontSize: '.6rem', display: 'flex', flexDirection: 'row' } : { fontSize: '.8rem' }}>
                                                            {listPathDocumentsJustificatifs.map((file, index) => (
                                                                <div key={index}>
                                                                    {file.name}
                                                                    <span
                                                                        className="material-symbols-outlined"
                                                                        onClick={() => handleDeleteFile(index)}
                                                                        style={{ fontSize: '200%' }}
                                                                    >
                                                                        delete
                                                                    </span>
                                                                </div>
                                                            ))}

                                                            {listPathDocumentsJustificatifsName.map((file, index) => (
                                                                <div key={index}>
                                                                    {file}
                                                                    <span
                                                                        className="material-symbols-outlined"
                                                                        onClick={() => handleDeleteFileName(index)}
                                                                        style={{ fontSize: '200%' }}
                                                                    >
                                                                        delete
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    <div className="submit-container">
                                        <button type="submit" className="submit-btn">Enregistrer</button>
                                    </div>

                                </div>
                            </div>}
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default ModifierEntree;
