import React, { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import Select from "react-select";
import { CustomToast } from '../../components/Toast/CustomToast';
import useDebounce from '../../hooks/useDebounce';
import ConfirmationDialog from '../../components/Confirmation/ConfirmationDialog';
import { axiosInstance, Vehicule, ModeleInspection, VehiculeInspection, VehiculeProbleme } from '../../services/api';
import $ from 'jquery';
import { AuthContext } from '../../contexts/AuthContext';

const ListInspectionsEchecs = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [vehicules, setVehicules] = useState([]);
    const [inspections, setInspections] = useState([]);
    const [formulaires, setFormulaires] = useState([]);

    const [filtredInspections, setFiltredInspections] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [idProblemeReconnu, setIdProblemeReconnu] = useState('');
    const [idProblemeCreate, setIdProblemeCreate] = useState('');
    const [confirmDialogOpenReconnu, setConfirmDialogOpenReconnu] = useState(false);
    const [confirmDialogOpenCreate, setConfirmDialogOpenCreate] = useState(false);

    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState('');

    const [filters, setFilters] = useState({
        idVehicule: '',
        vehicule: '',
        statutProbleme: '',
        priorite: '',
        dateSignalementDebut: moment("00", "hh").add(-4, 'days').format('YYYY-MM-DD'),
        dateSignalementFin: moment("00", "hh").add(3, 'days').format('YYYY-MM-DD')
    });

    const [search, setSearch] = useState("");

    const [enteredVehicleText, setEnteredVehicleText] = useState('');
    const debouncedVehiculeText = useDebounce(enteredVehicleText, 300);

    //table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredInspections].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredInspections(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idVehicule) => {
        setOpenAboutList(openAboutList === idVehicule ? null : idVehicule);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
    };

    const handleVehicleChange = (selectedOption) => {
        setSelectedVehicle(selectedOption);
        setSelectedVehicleId(selectedOption ? selectedOption.idVehicule : '');
        filters.idVehicule = selectedVehicleId;
        filters.vehicule = selectedOption;

        const updatedFilters = { ...filters, idVehicule: selectedOption ? selectedOption.idVehicule : '' };
        setFilters(updatedFilters);
    };

    //Elémént reconnu
    const handleConfirmReconnu = (idProbleme) => {
        // Show the modal when the confirm delete button is clicked
        setIdProblemeReconnu(idProbleme);
        setConfirmDialogOpenReconnu(true);
        formik.resetForm();
    };

    const handleCancelReconnu = () => {
        setConfirmDialogOpenReconnu(false);
    };

    const formik = useFormik({
        initialValues: {
            motif: '',
        },
        onSubmit: async (values) => {
            try {
                const postData = {
                    idProbleme: idProblemeReconnu,
                    motif: values.motif,
                    source: 'inspection',
                    ActionUtilisateur: authData.nomPrenom,
                    ActionIdUtilisateur: authData.idUser
                };

                console.log(postData);
                const response = await axiosInstance.put(`${VehiculeProbleme}/reconnu`, postData);
                CustomToast("Le problème a été reconnu avec succès", 'success');
                setConfirmDialogOpenReconnu(false);
                setIdProblemeReconnu('');
                getInspections(pageNumber, pageSize);

                // //navigate('/listcatpermis', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Issue does not exist") {
                    CustomToast("Problème n'existe pas", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Creer problème
    const handleConfirmCreerProbleme = (idProbleme) => {
        setIdProblemeCreate(idProbleme);
        setConfirmDialogOpenCreate(true);
    };

    const handleCancelCreate = () => {
        setConfirmDialogOpenCreate(false);
    };

    const handleCreate = async () => {
        try {
            const postData = {
                idProbleme: idProblemeCreate,
                source: 'inspection',
                ActionUtilisateur: authData.nomPrenom,
                ActionIdUtilisateur: authData.idUser
            };
            const response = await axiosInstance.put(`${VehiculeProbleme}/ouvert`, postData);
            setIdProblemeCreate('');
            setConfirmDialogOpenCreate(false);
            CustomToast('Le problème a été crée avec succès', 'success');
            getInspections(pageNumber, pageSize);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Issue does not exist") {
                CustomToast("Problème n'existe pas", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    //Load data
    const getInspections = async (page, newPerPage) => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (authData?.wilayas !== '' & authData?.wilayas != null) {
                const wilayasFilter = authData.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                setLoading(false);
                return;
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                setLoading(false);
                return
            }

            if (filters.idVehicule !== '') {
                queryParams.append("idVehicule", filters.idVehicule);
            }

            if (filters.statutProbleme !== '') {
                queryParams.append("statutProbleme", filters.statutProbleme);
            }

            if (filters.priorite !== '') {
                queryParams.append("priorite", filters.priorite);
            }

            if (filters.dateSignalementDebut !== '') {
                queryParams.append("dateSignalementDebut", filters.dateSignalementDebut);
            }

            if (filters.dateSignalementFin !== '') {
                queryParams.append("dateSignalementFin", filters.dateSignalementFin);
            }

            const response = await axiosInstance.get(`${VehiculeInspection}/GetInspectionsItemsEchoues?${queryParams}`);
            const resp = await response.data;
            setInspections(resp.data);
            setFiltredInspections(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const getVehicules = async (nomVehicule) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (nomVehicule !== '') {
                queryParams.append("nom", nomVehicule);
            }
            const response = await axiosInstance.get(`${Vehicule}/GetVehiculesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setVehicules(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getFormulaires = async () => {
        try {

            const response = await axiosInstance.get(`${ModeleInspection}`);
            const resp = await response.data;
            setFormulaires(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    //Search
    const handleSearch = (e) => {
        e.preventDefault();
        setPageNumber(1); // Reset page number when new search is triggered
        getInspections(1, pageSize); // Fetch inspections with updated filters
    };

    useEffect(() => {
        //if (debouncedVehiculeText) {
        getVehicules(debouncedVehiculeText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedVehiculeText]);

    useEffect(() => {
        //Load Data
        getFormulaires();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, []);

    useEffect(() => {
        //Load Data
        getInspections(pageNumber, pageSize);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize]);

    return (
        <section className="inspection-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className="elementdefect-sec wrap-all">

                    <form onSubmit={handleSearch}>
                        <div className="filter-wrap">

                            <div className="date-container">
                                <input
                                    type="date"
                                    value={filters.dateSignalementDebut}
                                    onChange={(e) => setFilters({ ...filters, dateSignalementDebut: e.target.value })}
                                    className='date-filter'
                                />
                            </div>

                            <div className="date-container">
                                <input
                                    type="date"
                                    value={filters.dateSignalementFin}
                                    onChange={(e) => setFilters({ ...filters, dateSignalementFin: e.target.value })}
                                    className='date-filter'
                                />
                            </div>

                            <Select
                                id="vehicule"
                                name="vehicule"
                                value={filters.vehicule}
                                onChange={(selectedOption) => {
                                    handleVehicleChange(selectedOption);
                                }}
                                onInputChange={(inputValue) => {
                                    // Update the entered text
                                    setEnteredVehicleText(inputValue);
                                }}
                                options={vehicules}
                                getOptionLabel={(option) => option.marque + '[' + option.modele + '] ' + option.immatriculation}
                                getOptionValue={(option) => option.idVehicule}
                                isClearable
                                placeholder="Sélectionnez véhicule"
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: '1rem',
                                        borderWidth: '1px',
                                        height: '3rem',
                                        borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                        backgroundColor: '#65458d',
                                        cursor: 'pointer',
                                        color: '#fff',
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#2581DC' : '#65458d',
                                        color: '#fff',
                                        cursor: 'pointer',
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                    placeholder: (provided, state) => ({
                                        ...provided,
                                        color: '#fff'
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                }}
                            />

                            <select
                                id="idModele"
                                name="idModele"
                                className="select-filter"
                                value={filters.idModele || ''}
                                onChange={(e) => setFilters({ ...filters, idModele: e.target.value || '' })}
                            >
                                <option value="">Sélectionnez formulaire</option>
                                {formulaires.map((formulaire) => (
                                    <option key={formulaire.idModele} value={formulaire.idModele}>{formulaire.titre}</option>
                                ))}
                            </select>

                            <select
                                id="statutProbleme"
                                name="statutProbleme"
                                className="select-filter"
                                value={filters.statutProbleme || ''}
                                onChange={(e) => setFilters({ ...filters, statutProbleme: e.target.value || '' })}
                            >
                                <option value="" defaultValue={true}>Sélectionnez statut</option>
                                <option value="en attent">En attent</option>
                                <option value="ouvert">Ouvert</option>
                                <option value="résolu">Résolu</option>
                                <option value="reconnu">Reconnu</option>
                                <option value="en retard">En retard</option>
                                <option value="fermé">Fermé</option>
                            </select>


                            <div className="filter">
                                {/* <a href="#"><span className="material-symbols-outlined">filter_alt</span></a> */}
                                <button type="submit" className="linkButton">
                                    <span className="material-symbols-outlined">filter_alt</span>
                                </button>
                            </div>
                        </div>
                    </form>

                    {filtredInspections?.length > 0 && <><div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        {/* <div className="search-element">
                            <p>Rechercher</p>
                            <input type="text" />
                        </div> */}
                    </div>
                        <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('vehiculeNom')}>Véhicule</th>
                                        <th>Date</th>
                                        <th>Statut</th>
                                        <th>Signalé par</th>
                                        <th>Éléments</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredInspections?.map((vehicule) => (
                                        <tr key={vehicule.idProbleme}>
                                            <td>{vehicule.vehiculeNom + ' ' + vehicule.vehiculeImmatriculation}</td>
                                            <td>{vehicule.problemeDateSignalement ? (moment(vehicule.problemeDateSignalement).format('DD-MM-YYYY')) : ''}</td>
                                            <td>
                                                {vehicule.problemeStatut === "résolu" ? (
                                                    <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>Résolu</>
                                                ) : vehicule.problemeStatut === "en attent" ? (
                                                    <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="orangered" /></svg>En attent</>
                                                ) : vehicule.problemeStatut === "fermé" ? (
                                                    <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="grey" /></svg>Fermé</>
                                                ) : vehicule.problemeStatut === "reconnu" ? (
                                                    <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="blue" /></svg>Reconnu</>
                                                ) : vehicule.problemeStatut === "ouvert" ? (
                                                    <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="yellow" /></svg>Ouvert</>
                                                ) : vehicule.problemeStatut === "en retard" ? (
                                                    <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>En retard</>
                                                ) : null}
                                            </td>
                                            <td>{vehicule.problemeSignalerPar}</td>
                                            <td>{vehicule.problemeTitre}</td>
                                            <td className="txt-center relative">
                                                <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(vehicule.idProbleme)}>
                                                    more_horiz
                                                </span>
                                                {openAboutList === vehicule.idProbleme && (
                                                    <div className="about-list">
                                                        <ul>
                                                            {((vehicule.problemeStatut === "en attent")) && <li>
                                                                <Link className='row row-center' to="/listinspectionsechecs" style={{ textDecoration: "none" }} onClick={() => handleConfirmCreerProbleme(vehicule.idProbleme)}>
                                                                    <span className="material-symbols-outlined">add</span>Créer un problème
                                                                </Link>
                                                            </li>}

                                                            {((vehicule.problemeStatut !== "en attent")) && <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()}>
                                                                    <span className="material-symbols-outlined">add</span>Créer un problème
                                                                </Link>
                                                            </li>}

                                                            {((vehicule.problemeStatut === "en attent")) && <li>
                                                                <Link className='row row-center' to="/listinspectionsechecs" style={{ textDecoration: "none" }} onClick={() => handleConfirmReconnu(vehicule.idProbleme)}>
                                                                    <span className="material-symbols-outlined">thumb_up</span>Reconnu
                                                                </Link>
                                                            </li>}

                                                            {((vehicule.problemeStatut !== "en attent")) && <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()}>
                                                                    <span className="material-symbols-outlined">thumb_up</span>Reconnu
                                                                </Link>
                                                            </li>}

                                                        </ul>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div> */}
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                const page = pageNumber - 2 + i;
                                return page <= totalPages && page > 0 && (
                                    <Link
                                        key={page}
                                        to="#"
                                        className={pageNumber === page ? 'pgn active' : 'pgn'}
                                        onClick={() => handlePageChange(page)}
                                    >
                                        {page}
                                    </Link>
                                );
                            })}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={(pageNumber === totalPages || totalPages === 0) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === totalPages || totalPages === 0) ? true : false}
                            >&raquo;</Link>
                            <span> Affichage de {(pageNumber === 1)?1:((pageNumber - 1) * pageSize+1)} à {pageNumber * pageSize} sur {totalItems} entrées</span>
                        </div>
                    </>}
                    {confirmDialogOpenReconnu && <div className="popcard-add-wilaya-diver">
                        <div className="popcard-add-wilaya-diver_inside">
                            <Link className="popcard-add-wilaya-diver_inside-close" to="#" onClick={handleCancelReconnu}>&#10006;</Link>
                            <h2 className="popcard-add-wilaya-diver_inside-title">Elémént reconnu</h2>
                            <div className="popcard-add-wilaya-diver_inside-form">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Commentaire</label>
                                            <textarea type="text"
                                                id="motif"
                                                name="motif"
                                                placeholder=""
                                                defaultValue={formik.values.motif}
                                                onChange={formik.handleChange}
                                                rows={4}
                                            />
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="btn-submit-form-new" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    <ConfirmationDialog
                        open={confirmDialogOpenCreate}
                        onClose={handleCancelCreate}
                        onConfirm={handleCreate}
                        titleMessage="Confirmer la création du problème"
                        bodyMessage={"Voulez-vous vraiment créer le problème ?"}
                        btnCancelText="Annuler"
                        btnConfirmText="Enregistrer"
                    />

                </div>
            </div>
        </section>
    );
}

export default ListInspectionsEchecs;
