import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import Select from "react-select";
import { CustomToast } from '../../components/Toast/CustomToast';
import { ExportInspectionToPdf } from './ExportInspectionToPdf';
import ConfirmationDialog from '../../components/Confirmation/ConfirmationDialog';
import useDebounce from '../../hooks/useDebounce';
import { axiosInstance, Vehicule, ModeleInspection, VehiculeInspection, Groupe } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const ListInspections = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [vehicules, setVehicules] = useState([]);
    const [inspections, setInspections] = useState([]);
    const [formulaires, setFormulaires] = useState([]);
    const [groupes, setGroupes] = useState([]);

    const [filtredInspections, setFiltredInspections] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [idVehiculeInspectionDelete, setIdVehiculeInspectionDelete] = useState('');
    const [confirmDialogOpenDelete, setConfirmDialogOpenDelete] = useState(false);

    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState('');

    const [filters, setFilters] = useState({
        idModele: '',
        idVehicule: '',
        vehicule: '',
        dateInspectionDebut: moment("00", "hh").add(-4, 'days').format('YYYY-MM-DD'),
        dateInspectionFin: moment("00", "hh").add(3, 'days').format('YYYY-MM-DD'),
        idGroupe: ''
    });

    const [search, setSearch] = useState("");

    const [enteredVehicleText, setEnteredVehicleText] = useState('');
    const debouncedVehiculeText = useDebounce(enteredVehicleText, 300);


    //table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredInspections].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredInspections(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idVehicule) => {
        setOpenAboutList(openAboutList === idVehicule ? null : idVehicule);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
        //consolelog("setPageNumber="+pageNumber)
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
        //console.log("setPageSize="+pageSize);
    };

    const handleVehicleChange = (selectedOption) => {
        setSelectedVehicle(selectedOption);
        setSelectedVehicleId(selectedOption ? selectedOption.idVehicule : '');
        filters.idVehicule = selectedVehicleId;
        filters.vehicule = selectedOption;

        const updatedFilters = { ...filters, idVehicule: selectedOption ? selectedOption.idVehicule : '' };
        setFilters(updatedFilters);
    };

    //Supprimer inspection
    const deleteInspection = async (idVehiculeInspection) => {
        try {
            const response = await axiosInstance.delete(`${VehiculeInspection}/${idVehiculeInspection}`);
            getInspections(pageNumber, pageSize);
            CustomToast("L'inspections a été supprimée avec succès", 'success');
        }
        catch (error) {

            console.log(error);
        }
    }

    const handleConfirmDelete = (idVehiculeInspection) => {
        // Show the modal when the confirm delete button is clicked
        //deleteInspection(idVehiculeInspection);
        setIdVehiculeInspectionDelete(idVehiculeInspection);
        setConfirmDialogOpenDelete(true);
    };

    const handleDelete = async () => {
        try {
            const response = await axiosInstance.delete(`${VehiculeInspection}/${idVehiculeInspectionDelete}`);
            setIdVehiculeInspectionDelete('');
            setConfirmDialogOpenDelete(false);
            CustomToast("L'inspections a été supprimée avec succès", 'success');
            getInspections(pageNumber, pageSize);
        }
        catch (error) {
            // Handle error
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpenDelete(false);
    };

    //Imprimer inspection
    // const handlePrint = (idVehiculeInspection) => {
    //     pintInspection(idVehiculeInspection);
    // };

    const pintInspection = async (idVehiculeInspection) => {
        try {

            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 1,
                idInspection: idVehiculeInspection
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            const response = await axiosInstance.get(`${VehiculeInspection}/GetInspections?${queryParams}`);
            const resp = await response.data;

            ExportInspectionToPdf(resp.data[0], idVehiculeInspection);
        }
        catch (error) {
            console.log(error);
        }
    }

    //Load data
    const getInspections = async (page, newPerPage) => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            //console.log(filters); // Filters won't be immediately updated here due to closure behavior

            if (authData?.wilayas !== '' & authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                setLoading(false);
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                setLoading(false);
                return
            }

            if (filters.idModele !== '') {
                queryParams.append("idModele", filters.idModele);
            }

            if (filters.idVehicule !== '') {
                queryParams.append("idVehicule", filters.vehicule.idVehicule);
            }

            if (filters.dateInspectionDebut !== '') {
                queryParams.append("dateInspectionDebut", filters.dateInspectionDebut);
            }

            if (filters.dateInspectionFin !== '') {
                queryParams.append("dateInspectionFin", filters.dateInspectionFin);
            }

            if (filters.idGroupe !== '') {
                queryParams.append("idGroupe", filters.idGroupe);
            }

            const response = await axiosInstance.get(`${VehiculeInspection}/GetInspections?${queryParams}`);
            const resp = await response.data;
            setInspections(resp.data);
            setFiltredInspections(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const getVehicules = async (nomVehicule) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (nomVehicule !== '') {
                queryParams.append("nom", nomVehicule);
            }
            const response = await axiosInstance.get(`${Vehicule}/GetVehiculesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setVehicules(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getFormulaires = async () => {
        try {

            const response = await axiosInstance.get(`${ModeleInspection}`);
            const resp = await response.data;
            setFormulaires(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    const getGroupes = async () => {
        try {

            const response = await axiosInstance.get(`${Groupe}`);
            const resp = await response.data;
            setGroupes(resp);
        }
        catch (err) {
            console.log(err);
        }
    }

    //Search
    const handleSearch = (e) => {
        e.preventDefault();
        setPageNumber(1); // Reset page number when new search is triggered
        getInspections(1, pageSize); // Fetch inspections with updated filters
    };

    useEffect(() => {
        //if (debouncedVehiculeText) {
        getVehicules(debouncedVehiculeText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedVehiculeText]);

    useEffect(() => {
        //Load Data
        getGroupes();
        getFormulaires();
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, []);

    useEffect(() => {
        //Load Data
        getInspections(pageNumber, pageSize);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize]);

    return (
        <section className="inspection-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className="inspection-sec wrap-all">
                    <Link to="/ajouterinspection" className="inspection-add color-primary-2 decoration-none">
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Commencer l'inspection
                        </div>
                    </Link>
                    <form onSubmit={handleSearch}>
                        <div className="filter-wrap">

                            <div className="date-container">
                                <input
                                    type="date"
                                    value={filters.dateInspectionDebut}
                                    onChange={(e) => setFilters({ ...filters, dateInspectionDebut: e.target.value })}
                                    className='date-filter'
                                />
                            </div>

                            <div className="date-container">
                                <input
                                    type="date"
                                    value={filters.dateInspectionFin}
                                    onChange={(e) => setFilters({ ...filters, dateInspectionFin: e.target.value })}
                                    className='date-filter'
                                />
                            </div>

                            <Select
                                id="vehicule"
                                name="vehicule"
                                value={filters.vehicule}
                                onChange={(selectedOption) => {
                                    handleVehicleChange(selectedOption);
                                }}
                                onInputChange={(inputValue) => {
                                    // Update the entered text
                                    setEnteredVehicleText(inputValue);
                                }}
                                options={vehicules}
                                getOptionLabel={(option) => option.marque + '[' + option.modele + '] ' + option.immatriculation}
                                getOptionValue={(option) => option.idVehicule}
                                isClearable
                                placeholder="Sélectionnez véhicule"
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: '1rem',
                                        borderWidth: '1px',
                                        height: '3rem',
                                        borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                        backgroundColor: '#65458d',
                                        cursor: 'pointer',
                                        color: '#fff',
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#2581DC' : '#65458d',
                                        color: '#fff',
                                        cursor: 'pointer',
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                    placeholder: (provided, state) => ({
                                        ...provided,
                                        color: '#fff'
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                }}
                            />

                            <select
                                id="idGroupe"
                                name="idGroupe"
                                className="select-filter"
                                value={filters.idGroupe || ''}
                                onChange={(e) => setFilters({ ...filters, idGroupe: e.target.value || '' })}
                            >
                                <option value="">Sélectionnez groupe</option>
                                {groupes.map((groupe) => (
                                    <option key={groupe.idGroupe} value={groupe.idGroupe}>{groupe.libelleGroupe}</option>
                                ))}
                            </select>

                            <select
                                id="idModele"
                                name="idModele"
                                className="select-filter"
                                value={filters.idModele || ''}
                                onChange={(e) => setFilters({ ...filters, idModele: e.target.value || '' })}
                            >
                                <option value="">Sélectionnez formulaire</option>
                                {formulaires.map((formulaire) => (
                                    <option key={formulaire.idModele} value={formulaire.idModele}>{formulaire.titre}</option>
                                ))}
                            </select>


                            <div className="filter">
                                {/* <a href="#"><span className="material-symbols-outlined">filter_alt</span></a> */}
                                <button type="submit" className="linkButton">
                                    <span className="material-symbols-outlined">filter_alt</span>
                                </button>
                            </div>
                        </div>
                    </form>

                    {filtredInspections?.length > 0 && <><div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        {/* <div className="search-element">
                            <p>Rechercher</p>
                            <input type="text" />
                        </div> */}
                    </div>
                        <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('vehiculeNom')}>Véhicule</th>
                                        <th>Date inspection</th>
                                        <th>Formulaire</th>
                                        <th>Utilisateur</th>
                                        <th>Éléments échoués</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredInspections?.map((vehicule) => (
                                        <tr key={vehicule.idVehiculeInspection}>
                                            <td>{vehicule.vehiculeNom + ' ' + vehicule.vehiculeImmatriculation}</td>
                                            <td>{vehicule.dateInspection ? (moment(vehicule.dateInspection).format('DD-MM-YYYY')) : ''}</td>
                                            <td>{vehicule.formulaireTitre}</td>
                                            <td>{vehicule.conducteurNom + " " + vehicule.conducteurPrenom}</td>
                                            <td>{
                                                <>
                                                    {vehicule.inspectionItems.map((item, index) => (item.itemTitre + ', '))}

                                                </>
                                            }</td>
                                            <td className="txt-center relative">
                                                <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(vehicule.idVehiculeInspection)}>
                                                    more_horiz
                                                </span>
                                                {openAboutList === vehicule.idVehiculeInspection && (
                                                    <div className="about-list">
                                                        <ul>
                                                            <li>
                                                                <Link className='row row-center' to={`/apercuinspection/${vehicule.idVehiculeInspection}`} style={{ textDecoration: "none" }}>
                                                                    <span className="material-symbols-outlined">visibility</span>Aperçu
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none" }} onClick={() => pintInspection(vehicule.idVehiculeInspection)}>
                                                                    <span className="material-symbols-outlined">print</span>Imprimer
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none" }} onClick={() => handleConfirmDelete(vehicule.idVehiculeInspection)}>
                                                                    <span className="material-symbols-outlined">delete</span>Supprimer
                                                                </Link>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div>
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                const page = pageNumber - 2 + i;
                                return page <= totalPages && page > 0 && (
                                    <Link
                                        key={page}
                                        to="#"
                                        className={pageNumber === page ? 'pgn active' : 'pgn'}
                                        onClick={() => handlePageChange(page)}
                                    >
                                        {page}
                                    </Link>
                                );
                            })}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={(pageNumber === totalPages || totalPages === 0) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === totalPages || totalPages === 0) ? true : false}
                            >&raquo;</Link>
                            <span> Affichage de {(pageNumber === 1)?1:((pageNumber - 1) * pageSize+1)} à {pageNumber * pageSize} sur {totalItems} entrées</span>
                        </div>
                    </>}
                    <ConfirmationDialog
                        open={confirmDialogOpenDelete}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression de l'inspection"
                        bodyMessage={"Voulez-vous vraiment supprimer cette inspection ? Toutes les données seront définitivement perdues."}
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer l'inspection"
                    />
                </div>
            </div>
        </section>
    );
}

export default ListInspections;
