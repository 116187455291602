import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment/moment';
import Select from "react-select";
import { CustomToast } from '../../components/Toast/CustomToast';
import useDebounce from '../../hooks/useDebounce';
import ConfirmationDialog from '../../components/Confirmation/ConfirmationDialog';
import ModalImage from '../../components/ModalImage/ModalImage';
import { axiosInstance, Vehicule, VehiculeProbleme, ProblemeImg } from '../../services/api';
import { AuthContext } from '../../contexts/AuthContext';

const ListProblemes = () => {
    const { authData } = useContext(AuthContext);
    const navigate = useNavigate();

    const [problemes, setProblemes] = useState([]);
    const [vehicules, setVehicules] = useState([]);

    const [filtredProblemes, setFiltredProblemes] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const [idProblemeDelete, setIdProblemeDelete] = useState('');
    const [idProblemeReconnu, setIdProblemeReconnu] = useState('');
    const [idProblemeCreate, setIdProblemeCreate] = useState('');
    const [idProblemeFermer, setIdProblemeFermer] = useState('');
    const [idProblemeResoudreNote, setIdProblemeResoudreNote] = useState('');
    const [idProblemeReouvrir, setIdProblemeReouvrir] = useState('');
    const [confirmDialogOpenDelete, setConfirmDialogOpenDelete] = useState(false);
    const [confirmDialogOpenReconnu, setConfirmDialogOpenReconnu] = useState(false);
    const [confirmDialogOpenCreate, setConfirmDialogOpenCreate] = useState(false);
    const [confirmDialogOpenFermer, setConfirmDialogOpenFermer] = useState(false);
    const [confirmDialogOpenResoudreNote, setConfirmDialogOpenResoudreNote] = useState(false);
    const [confirmDialogOpenReouvrir, setConfirmDialogOpenReouvrir] = useState(false);

    const [showImagePopUp, setShowImagePopUp] = useState(false);
    const [imageSrc, setImageSrc] = useState("");

    const [selectedVehicle, setSelectedVehicle] = useState('');
    const [selectedVehicleId, setSelectedVehicleId] = useState('');

    const [filters, setFilters] = useState({
        idVehicule: '',
        vehicule: '',
        statutProbleme: '',
        priorite: '',
        dateSignalementDebut: moment("00", "hh").add(-4, 'days').format('YYYY-MM-DD'),
        dateSignalementFin: moment("00", "hh").add(3, 'days').format('YYYY-MM-DD')
    });

    const [search, setSearch] = useState("");

    const [enteredVehicleText, setEnteredVehicleText] = useState('');
    const debouncedVehiculeText = useDebounce(enteredVehicleText, 300);

    //Table management
    const [sortBy, setSortBy] = useState(''); // Column name to sort by
    const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

    const handleSort = (column) => {
        setSortBy(column);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');

        let sortedTable = [...filtredProblemes].sort((a, b) => {
            const aValue = a[sortBy] || '';
            const bValue = b[sortBy] || '';

            if (sortOrder === 'asc') {
                return aValue.localeCompare(bValue);
            } else {
                return bValue.localeCompare(aValue);
            }
        });

        setFiltredProblemes(sortedTable);
    };

    const [openAboutList, setOpenAboutList] = useState(null);

    const toggleAboutList = (idVehicule) => {
        setOpenAboutList(openAboutList === idVehicule ? null : idVehicule);
    };

    const handlePageChange = (page) => {
        setPageNumber(page);
    };

    const handlePerRowsChange = (newPerPage) => {
        setPageSize(newPerPage);
    };

    const handleVehicleChange = (selectedOption) => {
        setSelectedVehicle(selectedOption);
        setSelectedVehicleId(selectedOption ? selectedOption.idVehicule : '');
        filters.idVehicule = selectedVehicleId;
        filters.vehicule = selectedOption;

        const updatedFilters = { ...filters, idVehicule: selectedOption ? selectedOption.idVehicule : '' };
        setFilters(updatedFilters);
    };

    //Elémént reconnu
    const handleConfirmReconnu = (idProbleme) => {
        // Show the modal when the confirm delete button is clicked
        setIdProblemeReconnu(idProbleme);
        setConfirmDialogOpenReconnu(true);
        formik.resetForm();
    };

    const handleCancelReconnu = () => {
        setConfirmDialogOpenReconnu(false);
    };

    const formik = useFormik({
        initialValues: {
            motif: '',
        },
        validationSchema: Yup.object({
            motif: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idProbleme: idProblemeReconnu,
                    motif: values.motif,
                    source: 'probleme',
                    ActionUtilisateur: authData.nomPrenom,
                    ActionIdUtilisateur: authData.idUser
                };

                //console.log(postData);
                const response = await axiosInstance.put(`${VehiculeProbleme}/reconnu`, postData);
                CustomToast("Le problème a été reconnu", 'success');
                setConfirmDialogOpenReconnu(false);
                setIdProblemeReconnu('');
                getProblemes(pageNumber, pageSize);

                // //navigate('/listcatpermis', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Issue does not exist") {
                    CustomToast("Problème n'existe pas", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Creer problème
    const handleConfirmCreerProbleme = (idProbleme) => {
        setIdProblemeCreate(idProbleme);
        setConfirmDialogOpenCreate(true);
    };

    const handleCancelCreate = () => {
        setConfirmDialogOpenCreate(false);
    };

    const handleCreate = async () => {
        try {
            const postData = {
                idProbleme: idProblemeCreate,
                source: 'probleme',
                ActionUtilisateur: authData.nomPrenom,
                ActionIdUtilisateur: authData.idUser
            };
            const response = await axiosInstance.put(`${VehiculeProbleme}/ouvert`, postData);
            setIdProblemeCreate('');
            setConfirmDialogOpenCreate(false);
            CustomToast('Le problème a été crée avec succès', 'success');
            getProblemes(pageNumber, pageSize);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Issue does not exist") {
                CustomToast("Problème n'existe pas", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    //Fermer problème
    const handleConfirmFermerProbleme = (idProbleme) => {
        setIdProblemeFermer(idProbleme);
        setConfirmDialogOpenFermer(true);
        formikFermer.resetForm();
    };

    const handleCancelFermer = () => {
        setConfirmDialogOpenFermer(false);
    };

    const formikFermer = useFormik({
        initialValues: {
            motifFermer: '',
        },
        validationSchema: Yup.object({
            motifFermer: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idProbleme: idProblemeFermer,
                    motif: values.motifFermer,
                    source: 'probleme',
                    ActionUtilisateur: authData.nomPrenom,
                    ActionIdUtilisateur: authData.idUser
                };

                const response = await axiosInstance.put(`${VehiculeProbleme}/fermer`, postData);
                setIdProblemeFermer('');
                setConfirmDialogOpenFermer(false);
                CustomToast('Le problème a été fermé avec succès', 'success');
                getProblemes(pageNumber, pageSize);

                // //navigate('/listcatpermis', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Issue does not exist") {
                    CustomToast("Problème n'existe pas", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Résoudre problème avec note
    const handleConfirmResoudreNote = (idProbleme) => {
        setIdProblemeResoudreNote(idProbleme);
        setConfirmDialogOpenResoudreNote(true);
        formikResoudreNote.resetForm();
    };

    const handleCancelResoudreNote = () => {
        setConfirmDialogOpenResoudreNote(false);
    };

    const formikResoudreNote = useFormik({
        initialValues: {
            motifResoudreNote: '',
        },
        validationSchema: Yup.object({
            motifResoudreNote: Yup.string()
                .required('Champ obligatoire')
        }),
        onSubmit: async (values) => {
            try {
                const postData = {
                    idProbleme: idProblemeResoudreNote,
                    motif: values.motifResoudreNote,
                    source: 'probleme',
                    ActionUtilisateur: authData.nomPrenom,
                    ActionIdUtilisateur: authData.idUser
                };

                const response = await axiosInstance.put(`${VehiculeProbleme}/resoudre`, postData);
                setIdProblemeResoudreNote('');
                setConfirmDialogOpenResoudreNote(false);
                CustomToast('Le problème a été résoulu avec succès', 'success');
                getProblemes(pageNumber, pageSize);

                // //navigate('/listcatpermis', { replace: true });
            } catch (error) {
                // Handle error
                if (!error?.response) {
                    CustomToast("Aucune réponse du serveur", 'error');
                } else if (error.response?.status === 400 && error.response?.data === "Issue does not exist") {
                    CustomToast("Problème n'existe pas", 'error');
                } else if (error.response?.status === 401) {
                    CustomToast("Non autorisé", 'error');
                } else {
                    CustomToast("Demande échoué", 'error');
                }
                console.log(error);
            }
        }
    });

    //Rouvrir problème
    const handleConfirmReouvrir = (idProbleme) => {
        // Show the modal when the confirm delete button is clicked
        setIdProblemeReouvrir(idProbleme);
        setConfirmDialogOpenReouvrir(true);
    };

    const handleCancelReouvrir = () => {
        setConfirmDialogOpenReouvrir(false);
    };

    const handleReouvrir = async () => {
        try {
            const postData = {
                idProbleme: idProblemeReouvrir,
                source: 'probleme',
                ActionUtilisateur: authData.nomPrenom,
                ActionIdUtilisateur: authData.idUser
            };
            const response = await axiosInstance.put(`${VehiculeProbleme}/reouvrir`, postData);
            //CustomToast("Le problème a été reconnu", 'success');
            setConfirmDialogOpenReouvrir(false);
            setIdProblemeReouvrir('');
            getProblemes(pageNumber, pageSize);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Issue does not exist") {
                CustomToast("Problème n'existe pas", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    //Supprimer problème
    const handleConfirmDelete = (idProbleme) => {
        // Show the modal when the confirm delete button is clicked
        //deleteProbleme(idProbleme);
        setIdProblemeDelete(idProbleme);
        setConfirmDialogOpenDelete(true);
    };

    const handleDelete = async () => {
        try {
            const postData = {
                idProbleme: idProblemeDelete,
                idUtilisateurAction: authData.idUser
            };
            const response = await axiosInstance.delete(`${VehiculeProbleme}`, {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify(postData)
            });

            setIdProblemeDelete('');
            CustomToast('Le problème a été supprimé avec succès', 'success');
            getProblemes(pageNumber, pageSize);
            setConfirmDialogOpenDelete(false);
        }
        catch (error) {
            // Handle error
            if (!error?.response) {
                CustomToast("Aucune réponse du serveur", 'error');
            } else if (error.response?.status === 400 && error.response?.data === "Issue not found") {
                CustomToast("Problème introuvable", 'error');
            } else if (error.response?.status === 401) {
                CustomToast("Non autorisé", 'error');
            } else if (error.response?.status === 404) {
                CustomToast("Demande incorrecte", 'error');
            } else {
                CustomToast("Demande échoué", 'error');
            }
            console.log(error);
        }
    }

    const handleCancelDelete = () => {
        setConfirmDialogOpenDelete(false);
    };

    //Afficher image
    const showImage = (documentImagePath) => {
        setImageSrc(documentImagePath);
        setShowImagePopUp(true);
        //console.log(ProblemeImg + documentImagePath)
    };

    const handleCancelImage = () => {
        setShowImagePopUp(false);
    };

    const handleOpenInNewTab = () => {
        const imageUrl = new URL(ProblemeImg + imageSrc).toString();
        window.open(imageUrl, '_blank');
    };

    //Load data
    const getProblemes = async (page, newPerPage) => {
        try {
            setLoading(true);
            const queryParams = new URLSearchParams({
                pageNumber: page,
                pageSize: newPerPage
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                setLoading(false);
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                setLoading(false);
                return
            }

            if (filters.idVehicule !== '') {
                queryParams.append("idVehicule", filters.idVehicule);
            }

            if (filters.statutProbleme !== '') {
                queryParams.append("statutProbleme", filters.statutProbleme);
            }

            if (filters.priorite !== '') {
                queryParams.append("priorite", filters.priorite);
            }

            if (filters.dateSignalementDebut !== '') {
                queryParams.append("dateSignalementDebut", filters.dateSignalementDebut);
            }

            if (filters.dateSignalementFin !== '') {
                queryParams.append("dateSignalementFin", filters.dateSignalementFin);
            }

            const response = await axiosInstance.get(`${VehiculeProbleme}/GetProblemes?${queryParams}`);
            const resp = await response.data;
            setProblemes(resp.data);
            setFiltredProblemes(resp.data);
            setPageNumber(resp.pageNumber);
            setPageSize(resp.pageSize);
            setTotalItems(resp.totalItems);
            setTotalPages(resp.totalPages);
            setLoading(false);
        }
        catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const getVehicules = async (nomVehicule) => {
        try {
            const queryParams = new URLSearchParams({
                pageNumber: 1,
                pageSize: 10
            });

            if (authData?.wilayas != null) {
                const wilayasFilter = authData?.wilayas
                queryParams.append("wilayas", wilayasFilter.join(','));
            }
            else {
                return
            }

            if (authData?.flottesAutorises != null) {
                const flottesAutorisesFilter = authData?.flottesAutorises
                queryParams.append("flottesAutorises", flottesAutorisesFilter.join(','));
            }
            else {
                return
            }

            if (nomVehicule !== '') {
                queryParams.append("nom", nomVehicule);
            }
            const response = await axiosInstance.get(`${Vehicule}/GetVehiculesAutocomplete?${queryParams}`);
            const resp = await response.data;
            setVehicules(resp.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    //Search
    const handleSearch = (e) => {
        e.preventDefault();
        setPageNumber(1); // Reset page number when new search is triggered
        getProblemes(1, pageSize); // Fetch inspections with updated filters
    };

    useEffect(() => {
        //if (debouncedVehiculeText) {
        getVehicules(debouncedVehiculeText);
        //}
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, debouncedVehiculeText]);

    useEffect(() => {
        //Load Data
        getProblemes(pageNumber, pageSize);
        return () => {
            // Perform any necessary cleanup (e.g., cancel pending requests)
        };
    }, [authData, pageNumber, pageSize]);


    return (
        <section className="problem-section" onClick={() => { if (openAboutList !== null) { setOpenAboutList(null) } }}>
            <div className="container">
                <div className="problem-sec wrap-all">

                    <Link to="/ajouterprobleme" className="problem-add color-primary-2 decoration-none">
                        <div className="add-new" id="add-vehc">
                            <span className="material-symbols-outlined">add</span>
                            Ajouter un problème
                        </div>
                    </Link>

                    <form onSubmit={handleSearch}>
                        <div className="filter-wrap">

                            <div className="date-container">
                                <input
                                    type="date"
                                    value={filters.dateSignalementDebut}
                                    onChange={(e) => setFilters({ ...filters, dateSignalementDebut: e.target.value })}
                                    className='date-filter'
                                />
                            </div>

                            <div className="date-container">
                                <input
                                    type="date"
                                    value={filters.dateSignalementFin}
                                    onChange={(e) => setFilters({ ...filters, dateSignalementFin: e.target.value })}
                                    className='date-filter'
                                />
                            </div>

                            <Select
                                id="vehicule"
                                name="vehicule"
                                value={filters.vehicule}
                                onChange={(selectedOption) => {
                                    handleVehicleChange(selectedOption);
                                }}
                                onInputChange={(inputValue) => {
                                    // Update the entered text
                                    setEnteredVehicleText(inputValue);
                                }}
                                options={vehicules}
                                getOptionLabel={(option) => option.marque + '[' + option.modele + '] ' + option.immatriculation}
                                getOptionValue={(option) => option.idVehicule}
                                isClearable
                                placeholder="Sélectionnez véhicule"
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        borderRadius: '1rem',
                                        borderWidth: '1px',
                                        height: '3rem',
                                        borderColor: state.isFocused ? 'rgba(115, 128, 236, 0.9)' : '#BEBEBE',
                                        backgroundColor: '#65458d',
                                        cursor: 'pointer',
                                        color: '#fff',
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isFocused ? '#2581DC' : '#65458d',
                                        color: '#fff',
                                        cursor: 'pointer',
                                    }),
                                    singleValue: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                    placeholder: (provided, state) => ({
                                        ...provided,
                                        color: '#fff'
                                    }),
                                    input: (provided, state) => ({
                                        ...provided,
                                        color: '#fff',
                                    }),
                                }}
                            />
                            <select
                                id="statutProbleme"
                                name="statutProbleme"
                                className="select-filter"
                                value={filters.statutProbleme || ''}
                                onChange={(e) => setFilters({ ...filters, statutProbleme: e.target.value || '' })}
                            >
                                <option value="" defaultValue={true}>Sélectionnez statut</option>
                                <option value="en attent">En attent</option>
                                <option value="ouvert">Ouvert</option>
                                <option value="résolu">Résolu</option>
                                <option value="reconnu">Reconnu</option>
                                <option value="en retard">En retard</option>
                                <option value="fermé">Fermé</option>
                            </select>
                            <select
                                id="priorite"
                                name="priorite"
                                className="select-filter"
                                value={filters.priorite || ''}
                                onChange={(e) => setFilters({ ...filters, priorite: e.target.value || '' })}
                            >
                                <option value="aucune" defaultValue={true}>Aucune priorité</option>
                                <option value="faible">Faible</option>
                                <option value="moyen">Moyen</option>
                                <option value="élevé">Élevé</option>
                                <option value="critique">Critique</option>
                            </select>


                            <div className="filter">
                                {/* <a href="#"><span className="material-symbols-outlined">filter_alt</span></a> */}
                                <button type="submit" className="linkButton">
                                    <span className="material-symbols-outlined">filter_alt</span>
                                </button>
                            </div>
                        </div>
                    </form>

                    {filtredProblemes?.length > 0 && <><div className="group-elements">
                        <div className="voir-elements">
                            <p></p>
                            <select
                                name="pageSize"
                                id="pageSize"
                                onChange={(e) => { handlePerRowsChange(e.target.value || 10); setPageNumber(1); }}
                            >
                                <option value="10" defaultValue={true}>10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                        </div>
                        {/* <div className="search-element">
                            <p>Rechercher</p>
                            <input type="text" />
                        </div> */}
                    </div>
                        <div className="table-wrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('vehiculeNom')}>Véhicule</th>
                                        <th>Statut</th>
                                        <th>Priorité</th>
                                        <th onClick={() => handleSort('problemeDateSignalement')}>Date de signalement</th>
                                        <th>Signalé par</th>
                                        <th>Résumé</th>
                                        <th>Image</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filtredProblemes?.map((vehicule) => (
                                        <tr key={vehicule.idProbleme}>
                                            <td>{vehicule.vehiculeNom + ' ' + vehicule.vehiculeImmatriculation}</td>
                                            <td>{
                                                vehicule.problemeStatut === "résolu" ?
                                                    <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="green" /></svg>Résolu</>
                                                    : vehicule.problemeStatut === "en attent" ?
                                                        <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="orangered" /></svg>En attent</>
                                                        : vehicule.problemeStatut === "fermé" ?
                                                            <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="grey" /></svg>Fermé</>
                                                            : vehicule.problemeStatut === "reconnu" ?
                                                                <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="blue" /></svg>Reconnu</>
                                                                : vehicule.problemeStatut === "ouvert" ?
                                                                    <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="yellow" /></svg>Ouvert</>
                                                                    : vehicule.problemeStatut === "en retard" ?
                                                                        <><svg height="11.87" width="15"><circle cx="6" cy="6" r="6" fill="red" /></svg>En retard</>
                                                                        : null
                                            }</td>
                                            <td>{
                                                vehicule.problemePriorite === "faible" ?
                                                    <><span className="material-symbols-outlined" style={{ color: "grey" }}>stat_1</span> Faible</>
                                                    : vehicule.problemePriorite === "moyen" ?
                                                        <><span className="material-symbols-outlined" style={{ color: "yellow" }}>stat_2</span> Moyen</>
                                                        : vehicule.problemePriorite === "élevé" ?
                                                            <><span className="material-symbols-outlined" style={{ color: "orange" }}>stat_3</span> Élevé</>
                                                            : vehicule.problemePriorite === "critique" ?
                                                                <><span className="material-symbols-outlined" style={{ color: "red" }}>error</span> Critique</>
                                                                : <><span className="material-symbols-outlined">radio_button_unchecked</span> Aucune</>
                                            }</td>
                                            <td>{vehicule.problemeDateSignalement ? (moment(vehicule.problemeDateSignalement).format('DD-MM-YYYY')) : ''}</td>
                                            <td>{vehicule.problemeSignalerPar}</td>
                                            <td>{vehicule.problemeTitre}</td>
                                            <td>
                                                {(vehicule.documentImagePath) && <Link to="#" style={{ textDecoration: "none" }} onClick={() => showImage(vehicule.documentImagePath)} title="Photo attachée">
                                                    <span className="material-symbols-outlined">Imagesmode</span>
                                                </Link>}
                                            </td>
                                            <td className="txt-center relative">
                                                <span className="material-symbols-outlined about-btn" onClick={() => toggleAboutList(vehicule.idProbleme)}>
                                                    more_horiz
                                                </span>
                                                {openAboutList === vehicule.idProbleme && (
                                                    <div className="about-list">
                                                        <ul>
                                                            {(vehicule.problemeStatut !== "résolu") && <li>
                                                                <Link className='row row-center' to={`/modifierprobleme/${vehicule.idProbleme}`} style={{ textDecoration: "none" }}>
                                                                    <span className="material-symbols-outlined">edit</span>Modifier
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.problemeStatut === "résolu") && <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()}>
                                                                    <span className="material-symbols-outlined">edit</span>Modifier
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.problemeStatut === "en attent") && <li>
                                                                <Link className='row row-center' to="/listproblemes" style={{ textDecoration: "none" }} onClick={() => handleConfirmCreerProbleme(vehicule.idProbleme)}>
                                                                    <span className="material-symbols-outlined">add</span>Créer un problème
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.problemeStatut !== "en attent") && <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()}>
                                                                    <span className="material-symbols-outlined">add</span>Créer un problème
                                                                </Link>
                                                            </li>}


                                                            {(vehicule.problemeStatut === "en attent") && <li>
                                                                <Link className='row row-center' to="/listproblemes" style={{ textDecoration: "none" }} onClick={() => handleConfirmReconnu(vehicule.idProbleme)}>
                                                                    <span className="material-symbols-outlined">thumb_up</span>Reconnu
                                                                </Link>
                                                            </li>}


                                                            {(vehicule.problemeStatut !== "en attent") && <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()}>
                                                                    <span className="material-symbols-outlined">thumb_up</span>Reconnu
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.problemeStatut === "ouvert" || vehicule.problemeStatut === "en retard") && <li>
                                                                <Link className='row row-center' to="/listproblemes" style={{ textDecoration: "none" }} onClick={() => handleConfirmResoudreNote(vehicule.idProbleme)} title='Résoudre avec une note lorsque le travil est terminé'>
                                                                    <span className="material-symbols-outlined">check_circle</span>Résoudre avec note
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.problemeStatut !== "ouvert" && vehicule.problemeStatut !== "en retard") && <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()} title='Résoudre avec une note lorsque le travil est terminé'>
                                                                    <span className="material-symbols-outlined">check_circle</span>Résoudre avec note
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.problemeStatut === "ouvert" || vehicule.problemeStatut === "en retard") && <li>
                                                                <Link className='row row-center' to="/listproblemes" style={{ textDecoration: "none" }} onClick={() => handleConfirmFermerProbleme(vehicule.idProbleme)} title="Termnez avec une note explquant pourquoi le problème n'a pas été résolu">
                                                                    <span className="material-symbols-outlined">block</span>Fermer
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.problemeStatut !== "ouvert" && vehicule.problemeStatut !== "en retard" && vehicule.problemeStatut !== "reconnu") && <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()} title="Termnez avec une note explquant pourquoi le problème n'a pas été résolu">
                                                                    <span className="material-symbols-outlined">block</span>Fermer
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.problemeStatut === "fermé" || vehicule.problemeStatut === "résolu") && <li>
                                                                <Link className='row row-center' to="/listproblemes" style={{ textDecoration: "none" }} onClick={() => handleConfirmReouvrir(vehicule.idProbleme)}>
                                                                    <span className="material-symbols-outlined">settings_backup_restore</span>Rouvrir
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.problemeStatut !== "fermé" && vehicule.problemeStatut !== "résolu" && vehicule.problemeStatut !== "reconnu") && <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()}>
                                                                    <span className="material-symbols-outlined">settings_backup_restore</span>Rouvrir
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.problemeStatut !== "résolu") && <li>
                                                                <Link className='row row-center' to="/listproblemes" style={{ textDecoration: "none" }} onClick={() => handleConfirmDelete(vehicule.idProbleme)}>
                                                                    <span className="material-symbols-outlined">delete</span>Supprimer
                                                                </Link>
                                                            </li>}

                                                            {(vehicule.problemeStatut === "résolu") && <li>
                                                                <Link className='row row-center' to="#" style={{ textDecoration: "none", color: "#BEBEBE", cursor: 'not-allowed' }} onClick={(event) => event.preventDefault()}>
                                                                    <span className="material-symbols-outlined">delete</span>Supprimer
                                                                </Link>
                                                            </li>}

                                                        </ul>
                                                    </div>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: totalPages }, (_, i) => (
                                <Link
                                    key={i + 1}
                                    to="#"
                                    className={i + 1 === pageNumber ? 'pgn active' : 'pgn'}
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={((pageNumber + 1) > totalPages) ? 'pgn disabled' : 'pgn'}
                                disabled={((pageNumber + 1) > totalPages) ? true : false}
                            >&raquo;</Link>
                        </div> */}
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePageChange(pageNumber - 1)}
                                className={(pageNumber === 1) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === 1) ? true : false}
                            >&laquo;</Link>
                            {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                                const page = pageNumber - 2 + i;
                                return page <= totalPages && page > 0 && (
                                    <Link
                                        key={page}
                                        to="#"
                                        className={pageNumber === page ? 'pgn active' : 'pgn'}
                                        onClick={() => handlePageChange(page)}
                                    >
                                        {page}
                                    </Link>
                                );
                            })}
                            <Link to="#" onClick={() => handlePageChange(pageNumber + 1)}
                                className={(pageNumber === totalPages || totalPages === 0) ? 'pgn disabled' : 'pgn'}
                                disabled={(pageNumber === totalPages || totalPages === 0) ? true : false}
                            >&raquo;</Link>
                            <span> Affichage de {(pageNumber === 1)?1:((pageNumber - 1) * pageSize+1)} à {pageNumber * pageSize} sur {totalItems} entrées</span>
                        </div>
                    </>}

                    <ConfirmationDialog
                        open={confirmDialogOpenDelete}
                        onClose={handleCancelDelete}
                        onConfirm={handleDelete}
                        titleMessage="Confirmer la suppression du problème"
                        bodyMessage={"Voulez-vous vraiment supprimer ce problème ? Toutes les données seront définitivement perdues."}
                        btnCancelText="Annuler"
                        btnConfirmText="Supprimer le problème"
                    />

                    <ConfirmationDialog
                        open={confirmDialogOpenCreate}
                        onClose={handleCancelCreate}
                        onConfirm={handleCreate}
                        titleMessage="Confirmer la création du problème"
                        bodyMessage={"Voulez-vous vraiment créer le problème ?"}
                        btnCancelText="Annuler"
                        btnConfirmText="Enregistrer"
                    />

                    <ConfirmationDialog
                        open={confirmDialogOpenReouvrir}
                        onClose={handleCancelReouvrir}
                        onConfirm={handleReouvrir}
                        titleMessage="Ré-ouvrir le problème"
                        bodyMessage={"Voulez-vous vraiment ré-ouvrir le problème sélectionné ?"}
                        btnCancelText="Annuler"
                        btnConfirmText="Enregistrer"
                    />

                    {confirmDialogOpenReconnu && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={handleCancelReconnu}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title">Problème reconnu</h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Commentaire<span className='requiredField'>*</span></label>
                                            <textarea type="text"
                                                id="motif"
                                                name="motif"
                                                placeholder=""
                                                defaultValue={formik.values.motif}
                                                onChange={formik.handleChange}
                                                rows={4}
                                                style={(formik.touched.motif && formik.errors.motif) ? { borderColor: "red" } : null}
                                            />
                                            {formik.touched.motif && formik.errors.motif && (
                                                <div className="invalid-feedback">{formik.errors.motif}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    {confirmDialogOpenFermer && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={handleCancelFermer}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title">Fermer le problème</h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formikFermer.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Commentaire<span className='requiredField'>*</span></label>
                                            <textarea type="text"
                                                id="motifFermer"
                                                name="motifFermer"
                                                placeholder=""
                                                defaultValue={formikFermer.values.motifFermer}
                                                onChange={formikFermer.handleChange}
                                                rows={4}
                                                style={(formikFermer.touched.motifFermer && formikFermer.errors.motifFermer) ? { borderColor: "red" } : null}
                                            />
                                            {formikFermer.touched.motifFermer && formikFermer.errors.motifFermer && (
                                                <div className="invalid-feedback">{formikFermer.errors.motifFermer}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    {confirmDialogOpenResoudreNote && <div className="popcard-add">
                        <div className="popcard-add_inside">
                            <Link className="popcard-add_inside-close" to="#" onClick={handleCancelResoudreNote}>&#10006;</Link>
                            <h2 className="popcard-add_inside-title">Résoudre le problème</h2>
                            <div className="popcard-add_inside-form">
                                <form onSubmit={formikResoudreNote.handleSubmit}>
                                    <div className="wrap-info">
                                        <div className="wrap-one">
                                            <label>Commentaire<span className='requiredField'>*</span></label>
                                            <textarea type="text"
                                                id="motifResoudreNote"
                                                name="motifResoudreNote"
                                                placeholder=""
                                                defaultValue={formikResoudreNote.values.motifResoudreNote}
                                                onChange={formikResoudreNote.handleChange}
                                                rows={4}
                                                style={(formikResoudreNote.touched.motifResoudreNote && formikResoudreNote.errors.motifResoudreNote) ? { borderColor: "red" } : null}
                                            />
                                            {formikResoudreNote.touched.motifResoudreNote && formikResoudreNote.errors.motifResoudreNote && (
                                                <div className="invalid-feedback">{formikResoudreNote.errors.motifResoudreNote}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="popcard-operation-pickup-details_inside-zone">
                                        <div className="buttons">
                                            <input type="submit" value="Enregistrer" className="submit-btn" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>}

                    <ModalImage
                        open={showImagePopUp}
                        onClose={handleCancelImage}
                        onConfirm={handleOpenInNewTab}
                        titleMessage="Photo attachée"
                        urlImage={ProblemeImg + imageSrc}
                        btnCancelText="Annuler"
                        btnConfirmText="Ouvrir dans un nouvel onglet"
                    />

                </div>
            </div>
        </section>
    );
}

export default ListProblemes;
